import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

import { filter, Subscription } from 'rxjs'
import { LinkAction } from 'src/app/common/enums'
import { Organization, Pod, UserPermission } from 'src/app/models/bebop.model'
import { UiNavState, UiNavStates } from 'src/app/models/ui.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { MainService } from 'src/app/services/main.service'
import { UserService } from 'src/app/services/user.service'
import { SessionService } from 'src/app/store/session/session.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { NavPermissions } from 'src/app/store/ui/ui.store'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'bebop-sidebar-navigation',
  styleUrls: ['./sidebar-navigation.component.scss'],
  templateUrl: './sidebar-navigation.component.html',
})
export class SidebarNavigationComponent implements OnInit, OnDestroy {
  initRoute: string
  state: UiNavState
  permissions: NavPermissions
  userPermissions: UserPermission
  environment = environment

  permissions$: Subscription
  router$: Subscription
  userPermissions$: Subscription
  pod$: Subscription
  org$: Subscription

  pod: Pod
  org: Organization

  _routePaths: Record<string, UiNavState>

  @ViewChild('navSection') navSection: ElementRef<HTMLElement>
  @ViewChild('navIndicator') navIndicator: ElementRef<HTMLElement>

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    setTimeout(() => this.updateSelectedRoute(), 200)
  }

  constructor(
    private router: Router,
    private uiQuery: UIQuery,
    private sessionService: SessionService,
    private util: BebopClientUtilsService,
    private userService: UserService,
    private mainService: MainService
  ) {}

  get podSelected() {
    return !!this.pod
  }

  get isSuspendedOrg() {
    return this.org?.suspended === true
  }

  ngOnInit(): void {
    this.state = 'Dashboard'
    this.initializeRoutePaths()

    this.permissions$ = this.uiQuery.getNavPermissions().subscribe((permissions) => {
      this.permissions = permissions
      this.checkPermissionsAndRedirect()
      setTimeout(() => this.updateSelectedRoute(), 500)
    })

    this.userPermissions$ = this.uiQuery.getUserPermissions().subscribe((u) => {
      this.userPermissions = u
    })

    this.pod$ = this.uiQuery.getSelectedPod().subscribe((pod) => {
      this.pod = pod
      this.checkPermissionsAndRedirect()
    })

    this.org$ = this.uiQuery.getSelectedOrg().subscribe((org) => {
      this.org = org
      this.checkPermissionsAndRedirect()
    })

    this.router$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((val: NavigationEnd) => {
        this.updateStateFromUrl(val.url)
        this.checkPermissionsAndRedirect()
      })
  }

  initializeRoutePaths(): void {
    this._routePaths = UiNavStates.reduce(
      (acc, u) => {
        acc[`/app/${u.toLowerCase()}`] = u
        return acc
      },
      <Record<string, UiNavState>>{}
    )
  }

  updateStateFromUrl(url: string): void {
    let state = this._routePaths[url]
    if (!state && url.includes('/app/bebopflex/')) {
      state = this._routePaths['/app/bebopflex']
    }
    if (!state && url.includes('/app/team/')) {
      state = this._routePaths['/app/team']
    }

    if (state) {
      if (!this.isValidRoute(state)) {
        console.error('[Route Navigation] Invalid route', {
          permissions: this.permissions,
          state,
          userPermissions: this.userPermissions,
        })
        this.router.navigate(['/app/dashboard'])
      }
      this.state = state
      setTimeout(() => this.updateSelectedRoute(), 500)
    }
  }

  checkPermissionsAndRedirect() {
    if (!this.isValidRoute(this.state)) {
      this.router.navigate(['/app/dashboard'])
    }
  }

  isValidRoute(state: UiNavState, onDefault = true): boolean {
    let permissions = this.permissions

    switch (state) {
      case 'Dashboard':
        return true

      case 'Dashboard-Earth':
        return permissions.earthDashboard ?? false

      case 'Workstations':
        return permissions.desktop ?? false

      case 'Projects':
        return permissions.projects ?? false

      case 'Broadcast':
        return permissions.cast ?? false

      case 'Team':
        return permissions.users ?? false

      case 'Upload':
        return (!environment.browser && permissions.upload) || false

      case 'Download':
        return (!environment.browser && permissions.download) || false

      case 'HotFolder':
        return (!environment.browser && permissions.hotfolder) || false

      case 'BebopLink':
        return (!environment.browser && this.userPermissions.links) || false

      case 'BebopFlex':
        return (!environment.browser && this.isBebopFlexNav()) || false

      case 'Settings':
        return true

      case 'Logout':
        return true

      case 'Help':
        return true

      default:
        return onDefault
    }
  }

  isBebopFlexNav() {
    let permissions = this.permissions
    return (
      this.userPermissions.links ||
      permissions.flex ||
      this.userService?.userEntitlement?.canAccessFlexProjects() ||
      this.userService?.userEntitlement?.canAccessFlexLinks()
    )
  }

  ngOnDestroy(): void {
    ;[this.permissions$, this.router$, this.pod$, this.org$, this.userPermissions$].forEach((e) => e?.unsubscribe())
  }

  updateSelectedRoute() {
    let el: HTMLElement = this.navSection?.nativeElement
    if (el) {
      let bar = el.querySelector('.bar.selected')
      if (!bar) return
      let rect = bar.getBoundingClientRect()
      let prect = el.getBoundingClientRect()
      let irect = this.navIndicator.nativeElement.getBoundingClientRect()
      this.navIndicator.nativeElement.style.top = `${rect.top - prect.top + (rect.height - irect.height) / 2}px`
      this.navIndicator.nativeElement.style.left = `0px`
      this.navIndicator.nativeElement.style.visibility = 'visible'
    }
  }

  openHelpCenter() {
    this.util.openExternalLink(LinkAction.SUPPORT_MAIN, { userId: this.userService.id })
  }

  openSupportChat() {
    window.Intercom('show')
  }

  async onNavSelect(state: UiNavState) {
    if (this.state === state) return

    if (state === 'Help') {
      this.openHelpCenter()
      return
    }

    setTimeout(() => this.updateSelectedRoute(), 100)

    if (state === 'Logout') {
      let ok = await this.mainService.promptBeforeTeardown()
      if (!ok) return
      this.sessionService.triggerLogout()
      return
    }

    this.router.navigate([`app/${state.toLowerCase()}`], {})
  }
}
