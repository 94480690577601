import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { interval, Subject, Subscription, takeUntil } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { BrowserUtilsService } from 'src/app/common/services/browser-utils.service'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import {
  RemoteFile,
  RemoteNavTree,
  RocketSession,
  UiDownloadFile,
} from 'src/app/components/rocket/common/classes/rocket-types'
import {
  SelectRocketUploadPathAction,
  SelectRocketUploadPathComponent,
} from 'src/app/components/rocket/uploads/modals/select-rocket-upload-path/select-rocket-upload-path.component'
import { Project } from 'src/app/models/bebop.model'
import { ElectronService } from 'src/app/services/electron.service'
import { DownloaderLifecycleService } from 'src/app/services/rocket/download/downloader-lifecycle.service'
import { LiveFilesQuery } from 'src/app/store/projects/live-files.query'
import { ProjectsQuery } from 'src/app/store/projects/projects.query'
import { ProjectsService as ProjectsStoreService } from 'src/app/store/projects/projects.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { isErrorResponse } from 'src/app/utils/response-utils'

import { LiveFilesService } from './../../../../services/live-files.service'
@Component({
  selector: 'cree8-live-files',
  styleUrl: './live-files.component.scss',
  templateUrl: './live-files.component.html',
})
export class LiveFilesComponent implements OnInit {
  @Input() project: any | null = null
  @Input() enableLink: boolean = false
  navTree: any
  loading = true
  liveFiles: any[] = []

  session: RocketSession<any, UiDownloadFile>
  changeLocationRef$: ModalOverlayRef<SelectRocketUploadPathComponent, SelectRocketUploadPathAction>
  private destroy$ = new Subject<void>()

  constructor(
    private router: Router,
    private uiQuery: UIQuery,
    private modalService: ComponentModalService,
    private toastService: ToastService,
    private liveFilesQuery: LiveFilesQuery,
    private liveFilesService: LiveFilesService,
    private electronService: ElectronService,
    private projectsStoreService: ProjectsStoreService,
    private projectsQuery: ProjectsQuery,
    private lcService: DownloaderLifecycleService,
    private browserUtil: BrowserUtilsService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (!org) {
          return
        }
        this.session = this.lcService.newRocketSession(org)
        this.initLiveFiles()
        this.projectsQuery
          .getFilesProps()
          .pipe(takeUntil(this.destroy$))
          .subscribe((filesProps) => {
            this.navTree = filesProps.navTree
          })
      })
  }

  detailProject(projectId) {
    this.projectsStoreService.setProjectPage('details')
    this.router.navigate(['/app/projects/view'], { queryParams: { activeTab: 'live', projectId: projectId } })
  }

  initLiveFiles(): void {
    this.liveFilesQuery
      .selectLiveFiles()
      .pipe(takeUntil(this.destroy$))
      .subscribe((files) => {
        this.loading = false
        this.liveFiles = files || []

        if (!this.project || !this.session?.rocket) return

        let relativePaths = this.liveFiles
          .filter((file) => file.status == 'STARTED' || file.status == 'LIVE' || true) // TEMP - true added for testing in dev
          .map((f) => f.relativePath)

        let project = this.project
        let options = {
          relativePaths,
        }
        this.session.rocket
          .fetchStats(
            {
              project: project,
            },
            options
          )
          .then((result: { entries: RemoteFile[] }) => {
            let entries = result?.entries || []
            entries.forEach((e) => {
              let file = this.liveFiles.find((f) => {
                return f.fullPath == e.name
              })
              file.size = e.size
              file.mtime = this.browserUtil.getRelativeTimeString(e.mtime)
            })
          })
          .catch((e: Error) => {})
      })
  }

  startPipeline(lfile: any, ev?: Event): void {
    this.changeLocationRef$ = this.modalService.open<SelectRocketUploadPathComponent, SelectRocketUploadPathAction>(
      SelectRocketUploadPathComponent,
      {
        animateFrom: ev?.target as Element,
        data: {
          project: this.project,
          session: this.session,
          title: 'Select Growing File Path',
        },
        hasBackdrop: true,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: true,
      }
    )

    this.changeLocationRef$.events().subscribe((e) => {
      if (e?.name == 'Cancel' || e?.name == 'Close') return

      const storage = this.project.storage
      let rootPath = [storage.transferServerMount, storage.directories.projects, this.project.folderName].join('/')

      if (this.project.solutions?.osOneFlex) {
        // Handle osOneFlex solution
        rootPath = [
          storage.transferServerMount,
          this.project.organization.flexMountCredential?.credentials?.lucid?.root_mount || '',
          storage.directories.projects,
          this.project.folderName,
        ].join('/')
      } else if (this.project.solutions?.osOneBlock) {
        // Handle osOneBlock solution
        rootPath = [
          storage.transferServerMount,
          this.project.organization.osOneBlockRootPath || '',
          storage.directories.projects,
          this.project.folderName,
        ].join('/')
      }

      let fullPath = rootPath.replace(/[/]?$/, '/') + e.path
      let relativePath = e.path

      this.liveFilesService
        .startPipeline(lfile._id, { fullPath, relativePath })
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (isErrorResponse(response)) {
            this.loading = false
            this.toastService.show({
              text: 'Start live pipeline failed',
              type: 'error',
            })
            console.error('Error starting pipeline', response.error.msg)
          } else {
            this.loading = false
            this.toastService.show({
              text: 'Live pipeline is starting! Hold tight!',
              type: 'warning',
            })
            this.initLiveFiles()
          }
        })
    })
  }

  stopPipeline(lfile: any): void {
    this.loading = true
    this.liveFilesService
      .stopPipeline(lfile._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          this.loading = false
          this.toastService.show({
            text: 'Stop live pipeline failed',
            type: 'error',
          })
          console.error('Error stopping pipeline', response.error.msg)
        } else {
          this.loading = false
          this.toastService.show({
            text: 'Stopping the media live',
            type: 'success',
          })
          lfile.status = 'STOPPED'
        }
      })
  }

  generateClass(lfile: any) {
    // eslint-disable-next-line @stylistic/js/max-len
    return `${lfile.isHot ? 'active' : ''} ${lfile.status == 'STARTED' || lfile.status == 'LIVE' ? 'started' : ''} ${lfile.status == 'STARTING' ? 'starting' : ''}`
  }

  copyHLSURL(url: any): void {
    this.electronService.copyToClipboard(url)

    this.toastService.show({
      text: 'HLS URL copied to clipboard',
      type: 'success',
    })
  }

  getPaths(project: Project, navTree: RemoteNavTree[]): { fullPath: string; relativePath: string } {
    let rootPath = ''
    let relativePath = ''
    const params: { fullPath: string; relativePath: string } = { fullPath: '', relativePath: '' }

    if (navTree && navTree.length) {
      // Use project and storage details to build rootPath
      const storage = project.storage
      rootPath = [storage.transferServerMount, storage.directories.projects, project.folderName].join('/')

      if (project.solutions?.osOneFlex) {
        // Handle osOneFlex solution
        rootPath = [
          storage.transferServerMount,
          project.organization.flexMountCredential?.credentials?.lucid?.root_mount || '',
          storage.directories.projects,
          project.folderName,
        ].join('/')
      } else if (project.solutions?.osOneBlock) {
        // Handle osOneBlock solution
        rootPath = [
          storage.transferServerMount,
          project.organization.osOneBlockRootPath || '',
          storage.directories.projects,
          project.folderName,
        ].join('/')
      }

      // Determine the relative path from the navTree
      relativePath = navTree[navTree.length - 1].path
      params.fullPath = rootPath + relativePath
      params.relativePath = relativePath.replace('/', '') // Remove starting `/` for relative path
    } else {
      params.fullPath = '/'
      params.relativePath = ''
    }

    return params
  }
}
