<nav [ngClass]="{ full: show }" [class.disable-overlay]="disableOverlay">
  <div class="navbar">
    <div class="nav-container">
      <div class="logo">
        <img
          ngSrc="/assets/img/cree8_logo_dark.svg"
          width="108"
          height="20"
          class="logo"
          alt="Cree8 | Creativity. Unleashed"
          (click)="onRedirectHome()"
        />
      </div>
      <div class="nav-item">
        <ul>
          <ng-container *ngFor="let item of listMenus">
            @if (item.type === 'item') {
            <li class="item" (click)="redirectTo(item)">{{ item.label }}</li>
            } @else {
            <li>
              <cree8-btn
                (click)="redirectTo(item)"
              >{{ item.label }}</cree8-btn
              >
            </li>

            }
          </ng-container>
        </ul>

        <div class="mobile-mode" (click)="toggle()">
          <div class="toggle">
            @if (!show) {
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon--bars"
            >
              <path
                d="M8.00049 20.0003H31.9999"
                stroke="#14141A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.00049 26.6663H31.9999"
                stroke="#14141A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.99988 13.3333H31.9993"
                stroke="#14141A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            } @else {
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon--close"
            >
              <path
                d="M8 8L30 30"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M30 8L8 30"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            }
          </div>
        </div>
      </div>
    </div>
    <div class="nav-item-mobile" *ngIf="show">
      <ul>
        <li
          class="item"
          (click)="onRedirectHome()"
          [class.active]="activeMenu === 'home'"
        >
          Home
        </li>
        <ng-container *ngFor="let item of listMenus">
          @if (item.type === 'item') {
          <li
            class="item"
            (click)="redirectTo(item)"
            [class.active]="activeMenu === item.label.toLowerCase()"
          >
            {{ item.label }}
          </li>
          } @else {
          <li class="btn">
            <cree8-btn
              (clicked)="redirectTo(item)"
              [fullwidth]="true"
              >{{ item.label }}</cree8-btn
            >
          </li>

          }
        </ng-container>
      </ul>
      <div class="social-media-container">
        <p>Follow Us</p>
        <social-media></social-media>
      </div>
      <div class="copyright">
        <p>© 2024 Cree8. All Right Reserved</p>
        <p>2350 Keystone Dr El Dorado Hills CA 95762-9543</p>
      </div>
    </div>
  </div>
</nav>

<cree8-calendly *ngIf="showCalendly" (closeModal)="showCalendly = false"></cree8-calendly>
