import { AfterViewInit, Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { Router } from '@angular/router'

import { interval, Subject, Subscription, take, takeUntil } from 'rxjs'
import { IConfirmationModal } from 'src/app/common/components/confirmation-modal/confirmation-modal.component'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { Project, User } from 'src/app/models/bebop.model'
import { IProjectDetail } from 'src/app/models/projects.model'
import { ProjectsQuery } from 'src/app/store/projects/projects.query'
import { ProjectsService as ProjectsStoreService } from 'src/app/store/projects/projects.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { isErrorResponse } from 'src/app/utils/response-utils'

import { ProjectFilesSettingsComponent } from '../../projects-detail/project-files-settings/project-files-settings.component'

@Component({
  selector: 'cree8-all-projects',
  styleUrl: './all-projects.component.scss',
  templateUrl: './all-projects.component.html',
})
export class AllProjectsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() projectId: string = ''
  @ViewChild('projectFilesSettings')
  projectFilesSettings: ProjectFilesSettingsComponent
  listProjects: IProjectDetail[] = []
  project: Project | undefined = undefined
  organization: any
  selectedProject: IProjectDetail | undefined = undefined
  loading = true
  viewType = 'grid'

  public counter: number = 10
  private countdownSubscription: Subscription | undefined

  private user: User
  private _destroy$ = new Subject()

  confirmation: IConfirmationModal = {
    description:
      'Are you sure you want to delete your project? All files within it will be permanently removed. This action cannot be undone.',
    title: 'Delete This Project?',
    type: 'danger',
  }

  modalDeleteActive = false

  modal: Cree8Modal = {
    counting: 0,
    current: 0,
    loading: false,
    title: '',
    type: 'close',
  } as Cree8Modal

  MODAL_TITLE_DELETE: string = 'Deleting Project'
  MODAL_TITLE_CANCEL: string = 'Project Deletion Canceled'
  MODAL_TITLE_SUCCESS_DELETE: string = 'Project Deleted'
  MODAL_TITLE_SETTINGS: string = 'Project Settings'

  constructor(
    private sessionQuery: SessionQuery,
    private projectsStoreService: ProjectsStoreService,
    private projectsQuery: ProjectsQuery,
    private toastService: ToastService,
    private uiQuery: UIQuery,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectsStoreService.resetStore()

    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this._destroy$))
      .subscribe((org) => {
        // Ensure org exists
        if (!org) {
          this.projectsStoreService.resetStore()
          return
        }

        this.user = this.sessionQuery.getUserValue()
        this.organization = org
        this.onRefresh()
      })

    this.projectsQuery
      .getViewTypeProjects()
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.viewType = response
      })
  }

  ngAfterViewInit(): void {
    this.renderProjects()
  }

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.complete()
    this.stopCountdown()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['projectId']) {
      this.projectId = changes['projectId'].currentValue
    }
  }

  onActionProject(props: any) {
    if (props.type === 'delete') {
      this.counter = 10
      this.modalDeleteActive = true
      this.selectedProject = props.data
      return
    }

    if (props.type === 'link') {
      this.selectedProject = props.data
      this.router.navigate(['/app/projects/view'], {
        queryParams: { activeTab: 'links', projectId: this.selectedProject?._id },
      })
      return
    }

    if (props.type === 'members') {
      this.selectedProject = props.data
      this.router.navigate(['/app/projects/view'], {
        queryParams: { activeTab: 'members', projectId: this.selectedProject?._id },
      })
      return
    }

    if (props.type === 'settings') {
      this.selectedProject = props.data
      // this.projectsStoreService.getProjectDetails(this.selectedProject?._id).subscribe((p) => {
      //   this.project = p.data
      //   this.projectsStoreService.setSelectedProject(p.data)
      this.modal.title = this.MODAL_TITLE_SETTINGS
      this.modal.type = 'confirmation'
      // })
      return
    }
  }

  onRefresh() {
    this.projectsStoreService.resetStore()
    this.loadFirstData()
  }

  loadFirstData() {
    this.loading = true
    this.projectsStoreService
      .getListProjects(this.user?._id, this.projectId, this.organization._id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.loading = false
        if (isErrorResponse(response)) {
          this.listProjects = []
        }
      })
  }

  renderProjects() {
    this.projectsQuery.getListProjects().subscribe((response) => {
      if (isErrorResponse(response)) {
        this.listProjects = []
      } else {
        this.listProjects = response
      }
    })
  }

  deleteProject() {
    this.modalDeleteActive = false
    this.modal.title = this.MODAL_TITLE_DELETE
    this.modal.labelConfirm = 'CANCEL DELETION'
    this.startCountdown()
  }

  onNext(event: any) {
    this.modal.title = ''
  }

  onCancelModal(event: any) {
    if (this.modal.title === this.MODAL_TITLE_CANCEL || this.modal.title === this.MODAL_TITLE_SUCCESS_DELETE) {
      this.modal.title = ''
      return
    }

    this.modal.title = this.MODAL_TITLE_CANCEL
    this.modal.labelConfirm = 'OK'

    this.stopCountdown()
    this.resetCountdown()
  }

  startCountdown() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe()
    }

    const countdown$ = interval(1000).pipe(take(this.counter + 1))

    this.countdownSubscription = countdown$.subscribe((value) => {
      this.counter = this.counter - 1
      if (this.counter === 0) {
        this.processDeleteProject()
      }
    })
  }

  stopCountdown() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe()
    }
  }

  resetCountdown() {
    this.stopCountdown()
    this.counter = 10
  }

  private processDeleteProject() {
    this.projectsStoreService.deleteProject(this.selectedProject._id).subscribe({
      error: (err) => {
        this.modal.title = ''
        this.toastService.show({
          text: err.error.msg,
          type: 'error',
        })
      },
      next: (res) => {
        this.modal.title = this.MODAL_TITLE_SUCCESS_DELETE
        this.modal.labelConfirm = 'OK'
        this.onRefresh()
      },
    })
  }

  loadMoreProjects() {
    this.projectsQuery.getStatusHasMore().subscribe((hasMore) => {
      if (hasMore && !this.loading) {
        this.projectsStoreService.updatePage()
        this.loading = true
        this.projectsStoreService
          .getListProjects(this.user?._id, this.projectId, this.organization._id, true)
          .subscribe({
            error: (err) => {
              this.loading = false
            },
            next: (res) => {
              this.loading = false
            },
          })
      }
    })
  }

  onCloseModal() {
    this.modal.title = ''
  }

  onCancelSettings(event: any) {
    this.modal.title = ''
  }

  onNextSettings(event: any) {
    this.projectFilesSettings.saveSettings()
  }

  onSuccessSubmitSettings(event: any) {
    this.modal.title = ''
    this.onRefresh()
  }
}
