import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { ChildProcess } from 'node:child_process'
import { catchError, Observable, of, Subscription } from 'rxjs'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { BrowserUtilsService } from 'src/app/common/services/browser-utils.service'
import { UtilsService } from 'src/app/common/services/utils.service'
import { Pagination } from 'src/app/common/types'
import {
  NDI_MSG_LENGTH,
  UiWorkstation,
  VmAction,
  VmPowerStatus,
  WorkstationErrorCodes,
  WorkstationLaunchState,
  WorkstationLaunchType,
} from 'src/app/components/workstation/classes/workstation-types'
import {
  CastOperator,
  CastQuality,
  ObjectId,
  Organization,
  Pod,
  UserWorkstation,
  Workstation,
} from 'src/app/models/bebop.model'
import {
  CastOperatorsResponse,
  GenericResponse,
  StandardDataResponse,
  StandardResponse,
  WorkstationActionResponse,
  WorkstationActionResponse as WorkstationVmResponse,
  WorkstationCastNotifyResponse,
  WorkstationChannelResponse,
  WorkstationLaunchVmResponse,
  WorkstationResponse,
  WorkstationsResponse,
} from 'src/app/models/response.model'
import { NdiStatus, StreamQuality } from 'src/app/models/ui.model'
import { AlertLogService } from 'src/app/services/alert-log.service'
import { BebopConfigService } from 'src/app/services/bebop-config.service'
import { ElectronService } from 'src/app/services/electron.service'
import { ExecutableService, WIN_CMD_NOT_FOUND, WIN_WHERE_NOT_FOUND } from 'src/app/services/executable.service'
import { MainService } from 'src/app/services/main.service'
import { UserService } from 'src/app/services/user.service'

import { SessionService } from '../session/session.service'

import { WorkstationQuery } from './workstation.query'
import {
  createInitialState,
  MAX_JOB_REQUEST_RETRY,
  MAX_JOB_REQUEST_RETRY_INTERVAL,
  RetryWorkstation,
  WORKSTATION_RESTART_INTERVAL,
  WorkstationJobs,
  WorkstationState,
  WorkstationStore,
  WorkstationTabInfo,
} from './workstation.store'

class WorkstationRestartManager {
  ws: RetryWorkstation[] = []
  ws$: Subscription

  mainService: MainService
  alertLog: AlertLogService

  constructor(
    private wquery: WorkstationQuery,
    private service: WorkstationService,
    private services: {
      mainService: MainService
      alertLog: AlertLogService
      userService: UserService
      toastService: ToastService
    }
  ) {
    this.init()
    this.mainService = services.mainService
    this.alertLog = services.alertLog
  }

  init() {
    this.startTicker()
    this.ws$ = this.wquery.getRetryWorkstations().subscribe((ws) => {
      this.ws = ws ?? []
      this.update()
    })
  }

  dispose() {
    this.ws$?.unsubscribe()
    this.stopTicker()
  }

  update() {
    if (!this.ws?.length) {
      this.stopTicker()
      return
    }

    this.startTicker()
  }

  run() {
    let rws = this.ws ?? []
    let now = Date.now()
    let candidates = rws.filter((r) => {
      if (!r?.workstation) return false
      let w = r.workstation

      // retryTill: Date
      // durationInMinutes: number
      // retryFailedLog: RetryWorkstationLog[]
      let tillTs = r.retryTill?.getTime?.() ?? 0
      if (tillTs <= now) return false

      let [log, _] = r.retryFailedLog ?? []

      if (log?.retryEndTime) {
        r.retryFailedLog = [
          {
            retryTime: new Date(),
            status: 'Init',
          },
          ...r.retryFailedLog,
        ]
        this.service.updateRetryWorkstation(r)
      }

      if (log?.status != 'Init') return false
      return true
    })

    candidates.forEach(async (c) => {
      let [log, prev, _] = c.retryFailedLog ?? []

      let lastRun = log?.retryEndTime ? log : prev

      if (!lastRun?.retryEndTime) return

      let lapseTs = now - lastRun.retryEndTime.getTime()

      if (lapseTs < WORKSTATION_RESTART_INTERVAL) return

      log.status = 'Queued'
      this.service.updateRetryWorkstation(c)

      let wsOrError = await this._getWorkstation(c.workstation._id)
      if (wsOrError == false) {
        log.status = 'Init'
        log.retryTime = new Date()
        this.service.updateRetryWorkstation(c)
        return
      }

      let ws = wsOrError.data
      if (!ws) return

      let { powerCodeDescription, powerCodeLabel, state } = this.service.getWorkstationCardPowerCodeLabel(ws)

      if (state === 'Running' || state === 'Started') {
        console.log('workstation configured for restart turns out ', state, '. Remove from watcher')
        this.service.removeRetryWorkstation(c)
        return
      }

      log.status = 'Retrying'
      this.service.updateRetryWorkstation(c)

      this.startWorkstation(c)
    })
  }

  startWorkstation(c: RetryWorkstation) {
    let w = c.workstation
    let name = w.DISPLAY_NAME || w.NAME || ''
    let label = `Auto Start workstation: ${name}`

    this.service.startWorkstation(w._id, 'Auto retried from client').subscribe((res: WorkstationActionResponse) => {
      c.retryFailedLog[0].retryEndTime = new Date()
      if (res?.error || !res?.success || res?.errObj) {
        c.retryFailedLog[0].status = 'Failed'

        let capacityError = false
        let errCode = typeof res.errObj == 'string' ? res.errObj : res.errObj?.code
        let msg = WorkstationErrorCodes[errCode]
        if (msg) {
          // retry on capacity issue
          if (WorkstationErrorCodes.InsufficientInstanceCapacity == msg) {
            capacityError = true
            c.retryFailedLog = [
              {
                retryTime: new Date(),
                status: 'Init',
              },
              ...c.retryFailedLog,
            ]
          }
        }

        msg = msg || errCode || res.error?.msg || res.message || 'Start failed'
        this.alertLog.logActivity(label, msg, 'error')

        if (capacityError) {
          this.service.updateRetryWorkstation(c)
        } else {
          // Other errors - do we need to keep trying ?

          this.services.toastService.show({
            text: `${label} is stopped due to non capacity constraints related error, ${msg || ''}`,
            type: 'warning',
          })

          // no console.error - alert log already sent this to server
          console.log(label, msg)

          this.service.removeRetryWorkstation(c)
        }
        return
      }
      // TODO - send email notification trigger
      // system notification

      // c.retryFailedLog[0].status = 'Success'
      // this.service.updateRetryWorkstation(c)

      this.mainService.addNotification(
        {
          autoShow: true,
          closeable: true,
          id: `retry#${w._id}`,
          onClose: async () => true,
          onSelect: async () => false,
          priority: 100,
          text: `Starting ${name}.`,
          type: 'success',
        },
        { beep: true, system: true }
      )

      this.sendNotificationActivity({
        topic: 'AUTO_RETRY_WORKSTATION_CAPACITY_ISSUE_RESOLVED',
        user: this.services.userService.id,
        workstation: w._id,
      })

      // success
      this.service.removeRetryWorkstation(c, true)
    })
  }

  sendNotificationActivity(data: { topic: WorkstationJobs; user: string; workstation: string }, retry = 0) {
    this.service.sendNotificationActivity(data).subscribe((res) => {
      if (res.error) {
        if (retry + 1 > MAX_JOB_REQUEST_RETRY) {
          console.error(`[JobActivity] ${data.topic} is failed`, data)
          return
        }

        setTimeout(() => this.sendNotificationActivity(data, retry + 1), MAX_JOB_REQUEST_RETRY_INTERVAL)
      }
    })
  }

  async _getWorkstation(w: string) {
    return new Promise<WorkstationResponse | false>((r) => {
      this.service.getWorkstation(w).subscribe((data: WorkstationResponse) => {
        if (data.error) return r(false)
        return r(data)
      })
    })
  }

  private _tickerHandle = -1

  startTicker() {
    if (this._tickerHandle != -1) return

    this._tickerHandle = window.setInterval(() => {
      try {
        this.run()
      } catch (e) {
        // no error log - avoid writing to network as its a ticker
        console.log('[WorkstationRestartManager] tick error ', e.message)
      }
    }, 1000)
  }

  stopTicker() {
    if (this._tickerHandle == -1) return
    window.clearInterval(this._tickerHandle)
    this._tickerHandle = -1
  }
}

@Injectable({ providedIn: 'root' })
export class WorkstationService {
  ndiProc: any

  restartManager: WorkstationRestartManager

  mainService: MainService

  constructor(
    private store: WorkstationStore,
    private http: HttpClient,
    private browserUtil: BrowserUtilsService,
    private util: UtilsService,
    private bebopConfig: BebopConfigService,
    private electronService: ElectronService,
    private sessionService: SessionService,
    private toastService: ToastService,
    private userService: UserService,
    private wquery: WorkstationQuery,
    private exeService: ExecutableService,
    private alertLog: AlertLogService
  ) {
    this.onBeforeQuit = this.onBeforeQuit.bind(this)
    this.electronService.registerHookFor('before-quit', this.onBeforeQuit)
    this.electronService.registerHookFor('message:before-quit', this.onBeforeQuit)
  }

  onLogin(mainService: MainService) {
    this.mainService = mainService
    this.restartManager?.dispose?.()
    this.restartManager = new WorkstationRestartManager(this.wquery, this, {
      alertLog: this.alertLog,
      mainService,
      toastService: this.toastService,
      userService: this.userService,
    })
    this.store.update(() => createInitialState())
  }

  onLogout() {
    this.restartManager?.dispose?.()
    this.store.update(() => createInitialState())
  }

  onBeforeQuit() {
    let value = this.store.getValue()

    if (value.ndi?.workstation) {
      this.stopNdiCasting()
    }
  }

  update(d: Partial<WorkstationState>) {
    this.store.update(
      (store) =>
        (store = {
          ...store,
          ...d,
        })
    )
  }

  updateWorkstationTabInfo(d: Partial<WorkstationTabInfo>) {
    this.store.update(
      (store) =>
        (store = {
          ...store,
          tabInfo: {
            ...store.tabInfo,
            ...d,
          },
        })
    )
  }

  addActiveAppSession(id: string, proc: ChildProcess) {
    let value = this.store.getValue()

    let activeWorkstations = { ...value.activeWorkstations }

    let activeWorkstationSessions = value.activeWorkstationSessions
    if (!activeWorkstations[id]) {
      activeWorkstations[id] = proc
      activeWorkstationSessions++
    } else {
      activeWorkstations[id]?.kill()
    }

    this.update({
      activeWorkstations,
      activeWorkstationSessions,
    })
  }

  removeActiveAppSession(id: string) {
    let value = this.store.getValue()

    let activeWorkstations = { ...value.activeWorkstations }

    let activeWorkstationSessions = value.activeWorkstationSessions
    if (!activeWorkstations[id]) {
      return
    }

    activeWorkstations[id]?.kill?.()
    delete activeWorkstations[id]
    activeWorkstationSessions--

    this.update({
      activeWorkstations,
      activeWorkstationSessions,
    })
  }

  setLoaderWorkstation(id: string, flag: boolean) {
    this.store.update(
      (store) =>
        (store = {
          ...store,
          loaderWorkstations: {
            ...store.loaderWorkstations,
            [id]: flag,
          },
        })
    )
  }

  // GET api ? it should be post!
  doWorkstation(id: string, action: VmAction, hint = '') {
    return this.http
      .get<WorkstationVmResponse>(
        `${this.bebopConfig.apiUrl}/api/v1/workstations/${id}/vmOperation/${action}?hint=${hint}`
      )
      .pipe(
        catchError((error: any) => {
          console.error(`[${action}] workstation`, id, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  get isDevMode() {
    return this.electronService.isDevMode
  }

  stopWorkstation(id: string, hint = '') {
    return this.doWorkstation(id, VmAction.STOP, hint)
  }

  rebootWorkstation(id: string, hint = '') {
    return this.doWorkstation(id, VmAction.REBOOT, hint)
  }

  startWorkstation(id: string, hint = '') {
    return this.doWorkstation(id, VmAction.START, hint)
  }

  stopIfCastWorkstation(w: Workstation) {
    let value = this.store.getValue()
    if (w?._id != value?.ndi?.workstation?._id) return
    this.stopNdiCasting()
  }

  stopCastWorkstation(id: string) {
    return this.http
      .post<WorkstationCastNotifyResponse>(`${this.bebopConfig.apiUrl}/api/v1/cast/client/${id}/stop`, {})
      .pipe(
        catchError((error: any) => {
          console.error(`[STOP] cast workstation`, id, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  terminateWorkstation(id: string) {
    return this.http
      .get<WorkstationVmResponse>(`${this.bebopConfig.apiUrl}/api/v1/workstations/${id}/vmTerminate`)
      .pipe(
        catchError((error: any) => {
          console.error(`On terminateWorkstation`, id, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  startBroadcast(orgId: ObjectId, data: any) {
    return this.http
      .get<StandardResponse>(`${this.bebopConfig.apiUrl}/api/v1/cast/earth/startv2/${orgId}`, {
        params: new HttpParams({ fromObject: data as any }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(`[START] Broadcast`, orgId, data, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  userFavsAndNicknames(data: { podId?: ObjectId; favorite?: boolean; nickname?: string }) {
    return this.http
      .get<StandardDataResponse<UserWorkstation[]>>(`${this.bebopConfig.apiUrl}/api/v1/user-workstations`, {
        params: new HttpParams({ fromObject: data as any }),
      })
      .pipe(
        catchError((error: any) => {
          console.error(`[userFavsAndNicknames] error`, data, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  updateUserWorkstation(data: { workstation: Workstation; favorite?: boolean; nickname?: string }) {
    let w = data.workstation
    let payload = {
      ...data,
      workstation: w._id,
    }

    return this.http
      .put<
        StandardDataResponse<UserWorkstation>
      >(`${this.bebopConfig.apiUrl}/api/v1/user-workstations/workstation/${w._id}`, payload)
      .pipe(
        catchError((error: any) => {
          console.error(`[updateUserWorkstation] error`, w?.DISPLAY_NAME, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  toggleUserFavorite(w: Workstation, favorite: boolean) {
    return this.updateUserWorkstation({ favorite, workstation: w })
  }

  editWorkstationNickname(w: Workstation, nickname: string) {
    return this.updateUserWorkstation({ nickname, workstation: w })
  }

  stopNdiStreamReceiver() {
    if (this.ndiProc) {
      if (this.electronService.isWindows) this.ndiProc?.kill?.()
      else this.ndiProc?.kill?.('SIGHUP')
    }

    this.update({
      ndi: {
        castActive: false,
        castOperator: null,
        message: '',
        quality: StreamQuality.SQ,
        workstation: null,
      },
    })
  }

  ndiConnectByWorkstation(w: Workstation, highStd: StreamQuality) {
    this.ndiConnectByCastOperator(w?.CAST_OPERATOR, highStd)
  }

  async ndiConnectByCastOperator(co: CastOperator, highStd: StreamQuality) {
    let w = co?.broadcastStation?.workstation
    if (!co?.castStation?.ip) {
      console.error('[ndiConnectByCastOperator] Workstation doesnt have cast operator set.', w?.NAME)
      this.toastService.show({
        text: 'Workstation doesnt have cast operator set.',
        type: 'error',
      })

      return
    }

    let basePath = ''
    if (!this.isDevMode && process.resourcesPath) basePath = process.resourcesPath + '/cast/'
    else basePath += './resources/cast/'

    if (this.electronService.isWindows) {
      if (!this.isDevMode && process.resourcesPath) basePath = process.resourcesPath + '\\cast\\win\\'
      else {
        let path = this.electronService.path
        let execPath = '.\\resources' //path.dirname(process.execPath)
        basePath = execPath + '\\cast\\win\\'
      }

      basePath = basePath.replace('\\app.asar', '')
    }

    //local dev..
    //basePath = elecApp.getAppPath() + '/cast/';

    let fromSrcName = 'from-'
    fromSrcName += w?.DISPLAY_NAME || w?.NAME || ''
    fromSrcName = fromSrcName.split(' ').join('-')

    let libPath = 'DYLD_LIBRARY_PATH=' + basePath
    let receiverCmd = basePath + 'cree8-cast-receiver'

    // Enable execute permission on the binary
    if (!this.electronService.isWindows) {
      await this.electronService.exec(`chmod +x ${receiverCmd}`)
    }

    //receiverCmd += ' -u ' + w.EXPERIMENTAL.CAST_STATION.CAST_IP + ':' + w.EXPERIMENTAL.CAST_STATION.CAST_PORT;
    //receiverCmd += ' -q ' + highStd;
    //receiverCmd += ' -e ' + BEBOP_CONFIG.APP_ENV;
    //receiverCmd += ' -t ' + (w.EXPERIMENTAL.CAST_STATION.CAST_VIEWER_TOKEN || 'NONE');

    let ndiSourceUrl = co?.castStation?.ip + ':' + (co?.castStation?.port || '5961')
    let ndiViewToken = co?.tokens?.viewer || 'NONE'

    let spawnArgs = []
    spawnArgs.push('-u')
    spawnArgs.push(ndiSourceUrl)
    spawnArgs.push('-q')
    spawnArgs.push(highStd == 'SQ' ? 'lowQ' : 'highQ')
    spawnArgs.push('-e')

    if (this.bebopConfig.env == 'cree8prod') {
      spawnArgs.push('prod')
    } else {
      spawnArgs.push('dev')
    }

    spawnArgs.push('-t')
    spawnArgs.push(ndiViewToken)

    let spawnOpts = {
      cwd: basePath,
      env: {
        DYLD_LIBRARY_PATH: basePath,
      },
    }

    console.log('M3 PATH: ', receiverCmd, spawnArgs, spawnOpts)
    this.stopNdiStreamReceiver()

    this.ndiProc = this.electronService.spawn(receiverCmd, spawnArgs, spawnOpts)

    let proc = this.ndiProc

    this.toastService.show({
      text: 'Requesting connection…',
      type: 'info',
    })

    proc.stdout.on('data', (data) => {
      let value = this.store.getValue()
      let strData = `${data}`
      //console.log(`stdout: ${data}`, strData.indexOf('Auth Success'));
      if (strData && strData.indexOf('Auth Success') > -1) {
        this.toastService.show({
          text: 'Receiving Cast…',
          type: 'info',
        })

        this.update({
          ndi: {
            ...value.ndi,
            castActive: true,
            castOperator: co,
            quality: highStd,
            workstation: w,
          },
        })

        this.notifyMCPClientCasting(co, highStd)
      } else if (strData && strData.indexOf('CAST_VIEWER_TOKEN') > -1) {
        this.toastService.show({
          text: 'Auth Error: Invalid Token',
          type: 'error',
        })
      } else if (strData && strData.indexOf('data received') > -1) {
        let ndiMsg = strData + '<br>' + (value.ndi?.message || '')
        if (ndiMsg.length > NDI_MSG_LENGTH) ndiMsg = ' -- '

        this.update({
          ndi: {
            ...value.ndi,
            castActive: true,
            castOperator: co,
            message: ndiMsg,
            quality: highStd,
            workstation: w,
          },
        })
      }
    })

    proc.stderr.on('data', (data) => {
      console.error(`stderr: ${data}`)
    })

    proc.on('close', (code: number) => {
      console.log(`child process exited with code ${code}`)
      this.ndiProc = null
      this.update({
        ndi: {
          castActive: false,
          castOperator: null,
          message: '',
          quality: StreamQuality.SQ,
          workstation: null,
        },
      })
    })
  }

  notifyCasting(co: CastOperator, highStd: StreamQuality, ndi: NdiStatus) {
    let current = !!ndi?.currentCastClientId
    let suffix = current ? `/${ndi?.currentCastClientId}` : ''

    let data: { quality: CastQuality; castOperatorInstance: string } = {
      castOperatorInstance: co?._id,
      quality: highStd == StreamQuality.HQ ? 'highQ' : 'lowQ',
    }

    let w = co?.broadcastStation?.workstation

    return this.http
      .post<WorkstationCastNotifyResponse>(`${this.bebopConfig.apiUrl}/api/v1/cast/client${suffix}`, data)
      .pipe(
        catchError((error: any) => {
          console.error(`[Notify] cast workstation`, w?.DISPLAY_NAME || co, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  notifyMCPClientCasting(co: CastOperator, highStd: StreamQuality) {
    let { ndi } = this.store.getValue()

    this.notifyCasting(co, highStd, ndi).subscribe((res: WorkstationCastNotifyResponse) => {
      if (res.error || res.status != 'Success') {
        this.toastService.show({
          text: 'Could not start casting.',
          type: 'error',
        })
        return
      }

      // TODO update status

      this.update({
        ndi: {
          ...ndi,
          currentCastClientId: res.castClient?._id,
        },
      })
    })
  }

  stopNdiCasting() {
    let value = this.store.getValue()

    if (!value.ndi) return this.stopNdiStreamReceiver()

    let w = value.ndi?.workstation
    let id = value.ndi?.currentCastClientId

    if (!id) return this.stopNdiStreamReceiver()

    this.stopCastWorkstation(id).subscribe((res: WorkstationCastNotifyResponse) => {
      this.stopNdiStreamReceiver()

      if (res.error || res?.status != 'Success') {
        this.toastService.show({
          text: `Could not remove casting for '${w?.DISPLAY_NAME || w?.NAME}'`,
          type: 'error',
        })
        return
      }

      this.update({
        ndi: {
          castActive: false,
          castOperator: null,
          currentCastClientId: null,
          message: '',
          quality: StreamQuality.SQ,
          workstation: null,
        },
      })
    })
  }

  async beforeLaunchPCoIP() {
    if (!this.electronService.isWindows) return true

    // windows

    let dll = 'msvcr120.dll'
    let result = await this.exeService.win32Where(dll)

    if (result?.exitCode == 0) return true

    if (
      result?.exitCode == 9009 ||
      result?.error?.indexOf(WIN_CMD_NOT_FOUND) > 0 ||
      result?.stderr?.indexOf(WIN_CMD_NOT_FOUND) > 0
    ) {
      console.error(result?.cmd ?? `where ${dll}`, 'error:', result?.error || result?.stderr)
      return true
    }

    if (
      result?.exitCode == 1 ||
      result?.error?.indexOf(WIN_WHERE_NOT_FOUND) > 0 ||
      result?.stderr?.indexOf(WIN_WHERE_NOT_FOUND) > 0
    ) {
      return await this.showMicrosoftPCoIPDLLPrompt(dll)
    }

    return true
  }

  async showMicrosoftPCoIPDLLPrompt(dll: string) {
    // only for windows
    if (!this.electronService.isWindows) return
    return await this.mainService.warningPrompt(
      null,
      {
        leftActionLabel: 'Cancel',
        message: `${dll?.toLocaleUpperCase()} is missing!`,
        rightActionLabel: 'Continue',
        subMessage: `Embedded PCoIP Client won’t run on windows if <em>the ${dll}</em> is missing, which is part of the 
          <a href="https://www.microsoft.com/en-us/download/details.aspx?id=40784" target="_blank" 
          rel="noopener noreferrer">Visual C++ 2013 bundle</a>.`,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
      }
    )
  }

  setDefaultChannel(w: Workstation, channel: string) {
    return this.http
      .post<WorkstationChannelResponse>(`${this.bebopConfig.apiUrl}/api/v1/workstations/${w._id}/defSessionChannel`, {
        defChannel: channel,
        desktop: w,
      })
      .pipe(
        catchError((error: any) => {
          console.error(`Workstation / defSessionChannel`, w._id, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  launchVm(data: { desktop: Workstation; organization: Organization; pod: Pod; clientConfig: any }) {
    return this.http
      .post<WorkstationLaunchVmResponse>(`${this.bebopConfig.apiUrl}/api/v2/pods/${data.pod?._id}/launchVM`, {
        clientConfig: data.clientConfig,
        desktop: data.desktop,
        organization: data.organization?._id,
      })
      .pipe(
        catchError((error: any) => {
          console.error(`Workstation / launchVm`, data.desktop._id, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  getCastOperators(pageOptions: Pagination, params?: any): Observable<Partial<WorkstationsResponse>> {
    // org is auto filled from interceptor
    return this.http
      .get<CastOperatorsResponse>(
        `${this.bebopConfig.apiUrl}/api/v1/cast-operators?size=${pageOptions.size}&page=${pageOptions.page}&${
          params ? new HttpParams({ fromObject: params }) : ''
        }`
      )
      .pipe(
        catchError((error: any) => {
          console.error('On getCastStations', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  getWorkstationCardBadges(w: Workstation) {
    let badges: WorkstationLaunchType[] = []

    // if (w.POWER_STATUS_CODE != VmPowerStatus.RUNNING || !w.READY_TO_LAUNCH) return badges

    if (['PCOIP_SDK', 'PCOIP_EXT'].includes(w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL)) {
      badges.push('PCoIP')
    }

    if (w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL === 'JUMP' || w.PREFS?.CAN_JUMP) {
      badges.push('Jump')
    }

    if (w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL === 'Parsec' || w.SESSION_LAUNCH_CHANNEL?.PARSEC?.peerID) {
      badges.push('Parsec')
    }

    if (w.PREFS?.PCOIP_SETTINGS?.ULTRA) {
      badges.push('Ultra')
    }

    return badges
  }

  getBroadcastStatus(w: Workstation) {
    let canBroadcast = this.userService.user?.userPreferences?.canBebopCastView ?? false

    if (!canBroadcast) return false

    if (!(w?.POWER_STATUS_CODE == VmPowerStatus.RUNNING || w?.POWER_STATUS_CODE == VmPowerStatus.REBOOTING))
      return false

    if (!w?.CAST_OPERATOR) return false
    if (!w?.CAST_OPERATOR?.is_active) return false

    return w?.CAST_OPERATOR?.broadcast?.isLive
  }

  getWorkstationCardPowerCodeLabel(w: Workstation): Partial<UiWorkstation> {
    let state: WorkstationLaunchState = 'Not Started'
    let powerCodeLabel = ''
    let powerCodeDescription = ''

    let code = w.POWER_STATUS_CODE

    // 'Not Started', 'Starting', 'Started', 'Running', 'Stopping'

    if (code == VmPowerStatus.RUNNING && !w.READY_TO_LAUNCH) {
      powerCodeDescription = 'Almost ready…'

      powerCodeLabel = 'Started'
      state = 'Started'
    } else if (code == VmPowerStatus.RUNNING) {
      powerCodeDescription = 'Good to go!'
      state = 'Running'
      powerCodeLabel =
        'Running ' +
        this.browserUtil.getRelativeTimeString(w.RUNNING_AT ? new Date(w.RUNNING_AT) : Date.now(), {
          numeric: 'auto',
          style: 'short', // 'short', 'long'
        })
    } else if (code == VmPowerStatus.STOPPED) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'

      if (w.retryLockSince) {
        let since = new Date(w.retryLockSince)
        if (this.util.isDate(since)) {
          // retry mode
          let userId = w.USER_ID?._id

          let selfAssigned = this.userService.id == userId

          if (!selfAssigned) {
            state = 'Retrying'
            powerCodeLabel =
              'Since ' +
              this.browserUtil.getRelativeTimeString(since, {
                numeric: 'auto',
                style: 'short', // 'short', 'long'
              })

            powerCodeDescription = 'Auto Retrying…'
          } else {
            let ws = this.wquery.getRetryWorkstationsValue()
            if (!ws?.find((x) => x?.workstation?._id == w._id)) {
              // release workstation from user
              this.releaseUserFromWorkstation({
                workstation: w,
              }).subscribe((e) => {})
            }
          }
        }
      }
    } else if (code == VmPowerStatus.STOPPING) {
      state = 'Stopping'
      powerCodeLabel = 'Stopping…'
    } else if (code == VmPowerStatus.STARTING) {
      state = 'Starting'
      powerCodeLabel = 'Starting…'
    } else if (code == VmPowerStatus.REBOOTING) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'
      powerCodeDescription = 'Rebooting…'
    } else if (code == VmPowerStatus.FAILED) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'
      powerCodeDescription = 'Failed'
    } else if (code == VmPowerStatus.SUSPENDING) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'
      powerCodeDescription = 'Suspending…'
    } else if (code == VmPowerStatus.SUSPENDED) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'
      powerCodeDescription = 'Suspended'
    } else if (code == VmPowerStatus.RESUMING) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'
      powerCodeDescription = 'Resuming…'
    } else if (code == VmPowerStatus.UNKNOWN) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'
      powerCodeDescription = 'Unknown (:'
    } else if (code == VmPowerStatus.PAUSING) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'
      powerCodeDescription = 'Pausing…'
    } else if (code == VmPowerStatus.PAUSED) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'
      powerCodeDescription = 'Paused'
    } else if (code == VmPowerStatus.REVERTING) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'
      powerCodeDescription = 'Reverting…'
    } else if (code == VmPowerStatus.TERMINATED || code == VmPowerStatus.QUEUED_FOR_TERMINATION) {
      state = 'Not Started'
      powerCodeLabel = 'Not Started'
      powerCodeDescription = VmPowerStatus.TERMINATED ? 'Terminated' : 'Terminating…'
    } else if (code == VmPowerStatus.LAUNCHING) {
      state = 'Running'
      powerCodeLabel =
        'Running ' +
        this.browserUtil.getRelativeTimeString(w.RUNNING_AT ? new Date(w.RUNNING_AT) : Date.now(), {
          numeric: 'auto',
          style: 'short', // 'short', 'long'
        })
      powerCodeDescription = 'Launching…'
    } else {
      console.log('Unhandled workstation power code', w, code)
    }

    let states: WorkstationLaunchState[] = ['Running', 'Starting', 'Started']
    if (w.IN_MAINTENANCE && !states.includes(state)) {
      powerCodeDescription = 'In Maintenance'
    }

    // TODO handle maintenance state - what to do !

    return { powerCodeDescription, powerCodeLabel, state }
  }

  updateCastQuality(data: { operatorId: ObjectId; quality: CastQuality }) {
    return this.http
      .post<GenericResponse>(`${this.bebopConfig.apiUrl}/api/v1/cast/operator/${data.operatorId}/updateRtmp`, data)
      .pipe(
        catchError((error: any) => {
          console.error(`Cast / updateCastQuality`, data, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  stopBroadcast(id: string) {
    return this.http.get<StandardResponse>(`${this.bebopConfig.apiUrl}/api/v1/cast/earth/stop/${id}`).pipe(
      catchError((error: any) => {
        console.error(`Cast / stopBroadcast`, id, error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getWorkstation(id: string) {
    return this.http.get<WorkstationResponse>(`${this.bebopConfig.apiUrl}/api/v1/workstations/${id}`).pipe(
      catchError((error: any) => {
        console.error(`getWorkstation response error `, id, error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  sendNotificationActivity(data: { topic: WorkstationJobs; user: string; workstation: string }) {
    return this.sessionService.sendNotificationActivity(data)
  }

  // retry workstation
  addRetryWorkstation(w: RetryWorkstation, updateOnly = false) {
    const thunk = () => {
      let value = this.store.getValue()
      let ws = [...(value.retryWorkstations ?? [])]

      let idx = ws.findIndex((e) => e?.workstation?._id == w?.workstation?._id)

      if (!updateOnly && idx == -1) {
        ws.push(w)
      } else if (updateOnly && idx != -1) {
        ws[idx] = { ...w[idx], ...w }
      } else return

      this.store.update(
        (store) =>
          (store = {
            ...store,
            retryWorkstations: ws,
          })
      )
    }

    // just first time
    if (updateOnly) {
      thunk()
      return
    }

    let subs = this.assignUserToWorkstation(w)

    subs?.subscribe?.((e: GenericResponse) => {
      let err = e.error?.msg || !e.success ? `Unable to assign user to workstation ${w?.workstation?.NAME}` : ''
      if (err) {
        console.error(`[addRetryWorkstation] error`, e.error?.msg || e.message)
        return
      }

      thunk()
    })
  }

  updateRetryWorkstation(w: RetryWorkstation) {
    this.addRetryWorkstation(w, true)
  }

  removeRetryWorkstation(w: RetryWorkstation, skipRelease = false) {
    let value = this.store.getValue()
    let ws = [...(value.retryWorkstations ?? [])]

    this.store.update(
      (store) =>
        (store = {
          ...store,
          retryWorkstations: ws.filter((e) => e?.workstation?._id != w?.workstation?._id),
        })
    )

    if (skipRelease) return

    let subs = this.releaseUserFromWorkstation(w)

    subs?.subscribe?.((e: GenericResponse) => {
      let err = e.error?.msg || !e.success ? `Unable to release user from workstation ${w?.workstation?.NAME}` : ''
      if (err) {
        console.error(`[removeRetryWorkstation] error`, e.error?.msg || e.message)
      }
    })
  }

  _assignOrReleaseWorkstation(w: Partial<RetryWorkstation>, assign = false) {
    let action = assign ? 'assign-me' : 'release-me'

    if (!w?.workstation?._id) {
      // use log instead of error here
      console.log(`[Auto-retry / ${action}] workstation id is not set`)
      return
    }

    return this.http
      .post<GenericResponse>(`${this.bebopConfig.apiUrl}/api/v1/workstations/${w?.workstation?._id}/${action}`, {})
      .pipe(
        catchError((error: any) => {
          // use log instead of error here
          console.log(`Auto-retry / ${action}`, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  assignUserToWorkstation(w: Partial<RetryWorkstation>) {
    return this._assignOrReleaseWorkstation(w, true)
  }

  releaseUserFromWorkstation(w: Partial<RetryWorkstation>) {
    return this._assignOrReleaseWorkstation(w)
  }

  notifyWorkstationStatus(ws: UiWorkstation[]) {
    // Caller will always return workstations of same pod
    let value = this.store.getValue()
    let rws = value.retryWorkstations ?? []
    if (!rws.length) return

    let lookup: { [key: string]: { ws: Workstation; state: WorkstationLaunchState } } = {}

    // all ws belows to same pod
    let pod: Pod

    ws.reduce((acc, w) => {
      if (!w?.source?._id) return
      pod = pod || w?.source?.POD_ID
      acc[w?.source?._id] = { state: w.state, ws: w?.source }
      return acc
    }, lookup)

    let nrws: RetryWorkstation[] = []

    let states: WorkstationLaunchState[] = ['Not Started', 'Starting']

    rws.forEach((r) => {
      let id = r?.workstation?._id
      let podId = r?.workstation?.POD_ID?._id

      if (podId != pod?._id) {
        nrws.push(r)
      } else if (lookup[id] && states.includes(lookup[id]?.state)) {
        nrws.push(r)
      }
    })

    this.store.update(
      (store) =>
        (store = {
          ...store,
          retryWorkstations: nrws,
        })
    )
  }

  getWorkstationRegions(options: any) {
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(options)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/regions`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getWebStoreAmi', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getWebStoreAmi(options: { processorType: string; region: string }) {
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(options)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/ami-webstore`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getWebStoreAmi', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getOsoneWorkstationGroupsAndConfig(options: { pod: string; region: string }) {
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(options)

    return this.http
      .get<any>(`${this.bebopConfig.apiUrl}/api/v1/pods/template-pod/ws-groups-and-config`, { params: httpParams })
      .pipe(
        catchError((error: any) => {
          console.error('On getOsoneWorkstationGroupsAndConfig', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  launchVMOSOne(options: { ami: string; region: string; organization: string; workstation: any; wsGroup: string }) {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/api/v1/pods/launch/osone-vm`, options).pipe(
      catchError((error: any) => {
        console.error('On launchVMOSOne', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }
}
