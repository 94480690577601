<div class="identifier"
  [class.beta]="identifier === 'beta'"
  [class.dev]="identifier === 'dev'"
  *ngIf="identifier === 'beta' || identifier === 'dev'"
>
<div class="ico-container">
  <ng-container *ngIf="identifier === 'beta'">
    <svg class="ico" viewBox="0 0 14 14">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Essential"></use>
    </svg>
  </ng-container>

  <ng-container *ngIf="identifier === 'dev'">
    <svg class="ico" viewBox="0 0 14 14">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Construction"></use>
    </svg>
  </ng-container>
  <span class="body3">{{ identifier === 'beta' ? 'BETA' : 'DEV' }}</span>
</div>
</div>
