<cree8-modal
  [modal]="modal"
  [hideOverlay]="false"
  [size]="'full'"
>
  <div class="modal-content calendly-modal">
    <div class="calendly-inline-widget"></div>
    <cree8-btn (click)="closeModal.emit()">Close</cree8-btn>
  </div>
</cree8-modal>
