/* eslint-disable @stylistic/js/max-len */
import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'

@Component({
  selector: 'cree8-projects-card',
  styleUrls: ['./projects.component.scss'],
  templateUrl: './projects.component.html',
})
export class ProjectsComponent implements OnInit {
  @Input() data: { info: string; available: boolean; desc: string }

  sanitizedIcon: any // Store sanitized SVG

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sanitizedIcon = this.sanitizer.bypassSecurityTrustHtml(this.icon)
  }

  onButtonClick = () => {
    this.router.navigate(['app/projects'])
  }

  get icon() {
    return `
<svg xmlns="http://www.w3.org/2000/svg" width="150" height="118" viewBox="0 0 150 118" fill="none">
  <g clip-path="url(#clip0_7032_1315)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M58.5366 32.4878C45.2548 32.4878 34.4878 43.2548 34.4878 56.5366V101.805C34.4878 115.087 45.2548 125.854 58.5366 125.854H75.5122C77.856 125.854 79.7561 127.754 79.7561 130.098C79.7561 132.441 77.856 134.341 75.5122 134.341H58.5366C40.5671 134.341 26 119.774 26 101.805V56.5366C26 38.5671 40.5671 24 58.5366 24H103.805C121.774 24 136.341 38.5671 136.341 56.5366V73.5122C136.341 75.856 134.441 77.7561 132.098 77.7561C129.754 77.7561 127.854 75.856 127.854 73.5122V56.5366C127.854 43.2548 117.087 32.4878 103.805 32.4878H58.5366Z" fill="url(#paint0_linear_7032_1315)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M115.122 94.7317C104.965 94.7317 96.7317 102.965 96.7317 113.122C96.7317 123.279 104.965 131.512 115.122 131.512C125.279 131.512 133.512 123.279 133.512 113.122C133.512 102.965 125.279 94.7317 115.122 94.7317ZM88.2439 113.122C88.2439 98.2776 100.278 86.2439 115.122 86.2439C129.966 86.2439 142 98.2776 142 113.122C142 127.966 129.966 140 115.122 140C100.278 140 88.2439 127.966 88.2439 113.122Z" fill="url(#paint1_linear_7032_1315)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M125.196 106.652C126.853 108.31 126.853 110.997 125.196 112.654L116.708 121.142C115.051 122.799 112.364 122.799 110.706 121.142L105.048 115.483C103.391 113.826 103.391 111.139 105.048 109.482C106.705 107.824 109.392 107.824 111.05 109.482L113.707 112.139L119.194 106.652C120.852 104.995 123.539 104.995 125.196 106.652Z" fill="url(#paint2_linear_7032_1315)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M48.6341 62.1951C48.6341 59.8513 50.5342 57.9512 52.878 57.9512H106.634C108.978 57.9512 110.878 59.8513 110.878 62.1951C110.878 64.539 108.978 66.439 106.634 66.439H52.878C50.5342 66.439 48.6341 64.539 48.6341 62.1951Z" fill="url(#paint3_linear_7032_1315)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M48.6341 96.1463C48.6341 93.8025 50.5342 91.9024 52.878 91.9024H72.6829C75.0268 91.9024 76.9268 93.8025 76.9268 96.1463C76.9268 98.4902 75.0268 100.39 72.6829 100.39H52.878C50.5342 100.39 48.6341 98.4902 48.6341 96.1463Z" fill="url(#paint4_linear_7032_1315)"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_7032_1315" x1="84" y1="24" x2="84" y2="153.257" gradientUnits="userSpaceOnUse">
      <stop stop-color="#494E55"/>
      <stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_7032_1315" x1="84" y1="24" x2="84" y2="153.257" gradientUnits="userSpaceOnUse">
      <stop stop-color="#494E55"/>
      <stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint2_linear_7032_1315" x1="84" y1="24" x2="84" y2="153.257" gradientUnits="userSpaceOnUse">
      <stop stop-color="#494E55"/>
      <stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint3_linear_7032_1315" x1="84" y1="24" x2="84" y2="153.257" gradientUnits="userSpaceOnUse">
      <stop stop-color="#494E55"/>
      <stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint4_linear_7032_1315" x1="84" y1="24" x2="84" y2="153.257" gradientUnits="userSpaceOnUse">
      <stop stop-color="#494E55"/>
      <stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
    </linearGradient>
    <clipPath id="clip0_7032_1315">
      <rect width="150" height="118" rx="10" fill="white"/>
    </clipPath>
  </defs>
</svg>
`
  }
}
