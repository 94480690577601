import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { finalize, Observable, take, tap } from 'rxjs'
import { BebopLink, Organization } from 'src/app/models/bebop.model'
import {
  ILinkQueryParams,
  IPayloadExpiration,
  IPayloadUpdateStatus,
  IResponseProjectLinks,
} from 'src/app/models/projects-links.model'
import { BebopConfigService } from 'src/app/services/bebop-config.service'

import { UIQuery } from '../ui/ui.query'

import { ProjectsLinksQuery } from './projects-links.query'
import { ProjectsLinksStore } from './projects-links.store'

@Injectable({
  providedIn: 'root',
})
export class ProjectsLinksService {
  queryParamsList: ILinkQueryParams = {} as ILinkQueryParams
  private org: Organization

  constructor(
    private http: HttpClient,
    private bebopConfig: BebopConfigService,
    private projectsLinksStore: ProjectsLinksStore,
    private projectsLinksQuery: ProjectsLinksQuery,
    private uiQuery: UIQuery
  ) {
    this.projectsLinksQuery.getQueryParamsListValue().subscribe((value) => {
      this.queryParamsList = value
    })

    this.uiQuery.getSelectedOrg().subscribe((org: Organization) => {
      this.org = org
    })
  }

  getListLinksProjects(projectId?: string, organizationId?: string): Observable<IResponseProjectLinks> {
    let queryParams = new HttpParams()
      .set('deactivated', this.queryParamsList.deactivated.toString())
      .set('expired', this.queryParamsList.expired.toString())
      .set('linkType', this.queryParamsList.linkType.toString())
      .set('linkTypeStrict', this.queryParamsList.linkTypeStrict.toString())
      .set('page', this.queryParamsList.page.toString())
      .set('size', this.queryParamsList.size.toString())
      .set('sortBy', this.queryParamsList.sortBy)
      .set('sortOrder', this.queryParamsList.sortOrder.toString())

    if (!this.org) {
      queryParams = queryParams.append('organization', organizationId)
    }

    if (projectId) {
      queryParams = queryParams.append('projectId', projectId)
    }

    if (this.queryParamsList.projectId) {
      queryParams = queryParams.append('projectId', this.queryParamsList.projectId)
    }

    this.projectsLinksStore.update((state) => ({
      ...state,
      loading: true,
      queryParamsList: {
        ...state.queryParamsList,
        projectId: projectId,
      },
    }))

    return this.http
      .get<IResponseProjectLinks>(`${this.bebopConfig.apiUrl}/api/v1/drop-link`, {
        params: queryParams,
      })
      .pipe(
        tap((response) => {
          this.projectsLinksStore.update((state) => ({
            ...state,
            listProjectsLinks: response.data || [],
          }))
        }),
        finalize(() => {
          this.projectsLinksStore.update((state) => ({
            ...state,
            loading: false,
          }))
        })
      )
  }

  filterByType(type: number, projectId?: string) {
    this.projectsLinksStore.update((state) => ({
      ...state,
      listProjectsLinks: [],
      queryParamsList: {
        ...state.queryParamsList,
        linkType: type,
        projectId: projectId !== undefined ? projectId : state.queryParamsList.projectId,
      },
    }))
    this.getListLinksProjects(projectId).pipe(take(1)).subscribe()
  }

  toggleStatus(status: 'expired' | 'deactivated') {
    this.projectsLinksStore.update((state) => ({
      ...state,
      queryParamsList: {
        ...state.queryParamsList,
        [status]: !state.queryParamsList[status],
      },
    }))
    this.getListLinksProjects().pipe(take(1)).subscribe()
  }

  activedLinks(status: boolean, payload: IPayloadUpdateStatus): Observable<{ success: boolean }> {
    let endpoint = 'activate'
    if (!status) {
      endpoint = 'deactivate'
    }

    return this.http
      .put<{
        success: boolean
      }>(`${this.bebopConfig.apiUrl}/api/v1/drop-link/${endpoint}`, { ...payload })
      .pipe()
  }

  sortData(sorting: string) {
    switch (sorting) {
      case 'deactivated':
        this.projectsLinksStore.update((state) => ({
          ...state,
          queryParamsList: {
            ...state.queryParamsList,
            deactivated: true,
            expired: false,
          },
        }))
        break
      case 'expired':
        this.projectsLinksStore.update((state) => ({
          ...state,
          queryParamsList: {
            ...state.queryParamsList,
            deactivated: false,
            expired: true,
          },
        }))
        break
      default:
        this.projectsLinksStore.update((state) => ({
          ...state,
          queryParamsList: {
            ...state.queryParamsList,
            deactivated: false,
            expired: false,
          },
        }))
        break
    }

    // this.getListLinksProjects().pipe(take(1)).subscribe()
  }

  updatedExpiration(data: BebopLink, time: number) {
    const payload: IPayloadExpiration = {
      expires: time,
      for: 'expiry',
      linkId: data._id,
      linkType: data.linkType,
      organizationId: data.organization._id,
      projectId: data.project._id,
    }

    return this.http.put<{ success: boolean }>(`${this.bebopConfig.apiUrl}/api/v1/drop-link/update`, { ...payload })
  }

  resetStore() {
    this.projectsLinksStore.update((state) => ({
      ...state,
      listProjectsLinks: [],
      queryParamsList: {
        deactivated: false,
        expired: false,
        linkType: 8,
        linkTypeStrict: false,
        page: 1,
        projectId: undefined,
        size: 25,
        sortBy: 'date_created',
        sortOrder: -1,
      },
    }))
  }
}
