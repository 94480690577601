import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'

import { catchError, finalize, of, tap } from 'rxjs'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { UiDownloadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import { BebopLink, LinkProtection, LinkType } from 'src/app/models/bebop.model'
import { LinkCreateResponse } from 'src/app/models/response.model'
import { LinkService } from 'src/app/store/rocket/link/link.service'

import { LinkExpirationComponent } from '../../pages/links-projects/link-expiration/link-expiration.component'
import { LinkProtectComponent } from '../../pages/links-projects/link-protect/link-protect.component'

@Component({
  selector: 'cree8-files-share-link',
  styleUrl: './share-link.component.scss',
  templateUrl: './share-link.component.html',
})
export class ShareLinkComponent implements OnInit {
  @ViewChild('linkExpirationComponent')
  linkExpirationComponent!: LinkExpirationComponent
  @ViewChild('linkProtectComponent')
  linkProtectComponent!: LinkProtectComponent
  @Input() data!: UiDownloadFile
  @Input() receiveLink: boolean = false
  linkResult!: BebopLink
  step = 1
  showPassword = false
  @Output() successCreate = new EventEmitter<boolean>()
  @Input() mediaInfo: any
  @Input() isNotesLink = false

  name = ''
  notes = ''
  file = ''
  unSafeFiles = false
  @Input() isLoading = false
  allowDownload = false

  constructor(
    private linkService: LinkService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.file = this.data.name
    this.step = 1
  }

  generateDropLink(): void {
    this.isLoading = true

    let settings = {}

    if (this.receiveLink) {
      settings = {
        allowUnsafeFiles: this.unSafeFiles,
        notes: this.notes,
        uploads: {
          allowUnsafeFiles: this.unSafeFiles,
          dropPath: this.data.userRoot + this.data.relativePath,
        },
      }
    } else if (this.isNotesLink) {
      settings = {
        allowDownload: this.allowDownload,
        notes: this.notes,
        review: {
          allowDownload: this.allowDownload,
          files: [{ metadata: { ...this.mediaInfo?.mediaInfo }, name: this.data.relativePath, size: this.data.size }],
        },
      }
    } else {
      settings = {
        downloads: {
          files: [{ name: this.data.relativePath, size: this.data.size }],
        },
        notes: this.notes,
      }
    }

    const link = {
      expires: this.linkExpirationComponent.getValue(),
      linkType: this.receiveLink ? LinkType.Receive : this.isNotesLink ? LinkType.Review : LinkType.Share,
      name: this.name,
      organizationId: this.data.project.organization._id,
      projectId: this.data.projectId,
      settings: settings,
    }

    this.linkService
      .createLink({
        expires: link.expires,
        linkType: this.receiveLink ? LinkType.Receive : this.isNotesLink ? LinkType.Review : LinkType.Share,
        name: this.name,
        organizationId: link.organizationId,
        projectId: link.projectId,
        settings: link.settings,
      })
      .pipe(
        tap((response: LinkCreateResponse) => this.handleCreateLinkResponse(response)),
        catchError((error) => {
          console.error('create link error', error)
          this.handleError(error?.msg ?? 'Create link failed', 'Create link failed')
          return of(null)
        }),
        finalize(() => {
          this.isLoading = false
          this.step = 2
        })
      )
      .subscribe()
  }

  private handleCreateLinkResponse(response: LinkCreateResponse): void {
    if (response.error) {
      this.handleError(response.error.msg ?? 'Create link failed', 'Create link failed')
      console.error('create link error', response.error)
    } else {
      this.linkResult = response.success
    }
  }

  updateLinkProtect(): void {
    if (!this.linkResult) return

    const data = {
      ...this.linkResult,
      password: this.linkProtectComponent.getValue().password,
    }
    const status = this.linkProtectComponent.getValue().status
    this.isLoading = true

    this.linkService
      .updateLinkProtect(data, status)
      .pipe(
        finalize(() => {
          this.isLoading = false
          this.step = 3
          this.successCreate.emit(true)
        }),
        catchError((error) => this.handleError(error, 'Link update failed'))
      )
      .subscribe()
  }

  private handleUpdateLinkResponse(response: any): void {
    if (response.error) {
      this.toastService.show({
        text: 'CREE8 Link update failed',
        type: 'error',
      })
      return
    }

    this.toastService.show({
      text: 'CREE8 Link has been updated',
      type: 'success',
    })
    this.step = 3
  }

  private handleError(error: any, defaultMessage: string) {
    console.error(defaultMessage, error)
    this.toastService.show({
      text: defaultMessage,
      type: 'error',
    })
    return of(null)
  }

  statusProtect(): LinkProtection {
    return this.linkProtectComponent.getValue().status
  }

  showPasswordProtect() {
    this.showPassword = true
    this.linkProtectComponent.showPasswordForm = true
  }

  copyLink() {
    navigator.clipboard
      .writeText(this.linkResult.fullLink)
      .then(() => {
        this.toastService.show({
          text: 'CREE8 Link has been copied to clipboard',
          type: 'success',
        })
      })
      .catch((err) => {
        this.toastService.show({
          text: 'Failed to copy text: ',
          type: 'error',
        })
      })
  }

  onChangeUnsafeFiles() {
    this.unSafeFiles = !this.unSafeFiles
  }

  changeAllowDownload() {
    this.allowDownload = !this.allowDownload
  }
}
