import { ChangeDetectorRef, Component, Input, Renderer2 } from "@angular/core"
import { Router } from "@angular/router"
declare let gtag: Function


@Component({
  selector: "navbar",
  templateUrl: "./navbar.component.html",
  styleUrl: "./navbar.component.scss",
})
export class NavbarComponent {
  show = false
  activeMenu = "home"
  showCalendly = false
  @Input() disableOverlay = false

  @Input() listMenus = [
    {
      id: "pricing",
      label: "Pricing",
      href: "https://cree8.io/#pricing",
      type: "item",
    },
    {
      id: "contact",
      label: "Contact",
      href: "https://cree8.io/contact",
      type: "item",
    },
    {
      id: "blog",
      label: "Blog",
      href: "https://blog.cree8.io/blog",
      type: "item",
    },
    {
      id: "login",
      label: "Log in",
      href: "https://my.cree8.io/",
      type: "item",
    },
    {
      id: "signup",
      label: "Sign Up",
      href: "https://store.cree8.io/",
      type: "item",
    },
    {
      id: "demo",
      label: "BOOK A DEMO",
      href: "https://calendly.com/cree8/cree8-demo?month=2024-07",
      type: "button",
    },
  ]
  @Input() direct = false
  @Input() backToHome = false

  constructor(private cdr: ChangeDetectorRef, private router: Router, private renderer: Renderer2) {}

  ngOnInit(): void {
    if(this.router.url.includes('/contact')){
      this.activeMenu = 'contact'
    }

    if (this.router.url.includes("#pricing")) {
      this.activeMenu = "pricing"
    }
  }

  toggle() {
    this.show = !this.show
    this.cdr.detectChanges()
  }

  redirectTo(item: any) {
    if (item.target === "scroll") {
      const element = document.getElementById(item.href)
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })

        if (item.href === "pricing") {
          this.activeMenu = "pricing"
        }
      } else {
        this.router.navigate(["/"], { fragment: item.href })
      }

      return
    }

    if (item.target === "current") {
      this.router.navigate([item.href])
      return
    }

    if(item.id === 'demo'){
      gtag('event', 'DEMO_BUTTON', {
        'event_category': 'Form Submission',
        'event_label': 'Lead Form',
        'value': 1
      })

      gtag('event', 'conversion', {
        'send_to': 'AW-11321015870/D2P4CO2-pNMZEL78o5Yq',
        'event_category': 'Demo Button Click',
        'event_label': 'Book appointments'
      })

      this.showCalendly = true
      return
    }

    window.location.replace(item.href)
  }

  loadCalendlyScript() {
    // Load the Calendly stylesheet
    const link = this.renderer.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://assets.calendly.com/assets/external/widget.css'
    this.renderer.appendChild(document.head, link)

    // Load the Calendly script
    const script = this.renderer.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    this.renderer.appendChild(document.body, script)

    script.onload = () => {
      // Initialize Calendly after the script has loaded
      (window as any).Calendly.initBadgeWidget({
        url: 'https://calendly.com/cree8/cree8-demo/30min',
        text: 'Schedule time with me',
        color: '#0069ff',
        textColor: '#ffffff'
      })
    }
  }

  onRedirectHome() {
    if (this.direct) {
      const element = document.getElementById("hero")
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
        this.activeMenu = "home"
      } else {
        this.router.navigate(["/"])
      }
    } else {
      window.location.replace("https://cree8.io/")
    }
  }
}
