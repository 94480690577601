import { AfterViewInit, Component, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core'
import { Cree8Modal } from '../../cree8-modal/cree8-modal.component'

export {}
declare global {
   interface Window {
      Calendly: any
   }
}
declare let gtag: Function


@Component({
  selector: 'cree8-calendly',
  templateUrl: './calendly.component.html',
  styleUrl: './calendly.component.scss'
})
export class CalendlyComponent implements AfterViewInit {
  url: string = 'https://calendly.com/cree8/cree8-demo'

  modal: Cree8Modal = {
    title: 'Booking Appointment',
    counting: 0,
    current: 0,
    type: 'close',
    loading: false,
    contentOnly: true,
  } as Cree8Modal

  @Output() closeModal = new EventEmitter<void>()

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadCalendlyWidget()
    }, 1000)

    this.setupEventListener()
  }

  loadCalendlyWidget() {
    console.log('loadCalendlyWidget')
    window.Calendly.initInlineWidget({
       url: this.url,
       parentElement: document.querySelector('.calendly-inline-widget'),
       prefill: {}
    })
  }

  setupEventListener() {
    window.addEventListener('message', (event) => {
      // Ensure the message comes from Calendly's iframe
      if (event.origin === 'https://calendly.com' && event.data.event === 'calendly.event_scheduled') {
        console.log('Event scheduled')

        gtag('event', 'conversion', {
          'send_to': 'AW-11321015870/s103CN6lwuYZEL78o5Yq',
          'event_category': 'Scheduled Demo',
          'event_label': 'Book appointments'
        })

        gtag('event', 'Scheduled_Demo', {
          event_category: 'Submit lead form',
          event_label: 'Lead Form',
          value: 1,
        })
      }
    })
  }


}
