// live-files.query.ts
import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'

import { map, Observable } from 'rxjs'

import { LiveFilesState, LiveFilesStore } from './live-files.store'

@Injectable({ providedIn: 'root' })
export class LiveFilesQuery extends Query<LiveFilesState> {
  constructor(protected store: LiveFilesStore) {
    super(store)
  }

  selectLiveFiles() {
    return this.select((state) => state.liveFiles)
  }

  selectProjectActiveStatus(projectId: string): Observable<{
    hasLiveFile: boolean
    isLive: boolean
    status: string
  }> {
    return this.select((state) => state.liveFiles).pipe(
      map((liveFiles) => {
        // If liveFiles is undefined or empty, return an object with both values as false
        if (!liveFiles || liveFiles.length === 0) {
          return {
            hasLiveFile: false,
            isLive: false,
            status: 'STOPPED',
          }
        }

        // Find the project
        const liveFile = liveFiles.find((file) => file.project?._id === projectId)

        // Return true/false based on whether the project exists in the live files
        return {
          hasLiveFile: !!liveFile, // If project exists, hasLiveFile is true, else false
          isLive: liveFile ? liveFile.isLive : false, // Determine if the project status is 'LIVE'
          status: liveFile?.status,
        }
      })
    )
  }
}
