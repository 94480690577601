import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class LiveFilesService {
  private apiUrl = '/api/v1/live-files'

  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {}

  /**
   * Get the live files for the current organization
   *
   * @param params Optional parameters for the request
   * @return Observable containing the response data or error information
   */
  getLiveFiles(params?: any): Observable<any> {
    // Default parameters if no arguments are passed
    if (!params) {
      params = {}
    }
    let httpParams = new HttpParams().appendAll(params)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}${this.apiUrl}`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getLiveFiles', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  /**
   * Create a new live file
   *
   * @param params The data to send with the POST request
   * @return Observable containing the response data or error information
   */
  createLiveFile(params: any): Observable<any> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}${this.apiUrl}`, params).pipe(
      catchError((error: any) => {
        console.error('On createLiveFile', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  /**
   * Delete a live file
   *
   * @param id The ID of the live file to delete
   * @return Observable containing the response data or error information
   */
  deleteLiveFile(id: string): Observable<any> {
    return this.http.delete<any>(`${this.bebopConfig.apiUrl}${this.apiUrl}/${id}`).pipe(
      catchError((error: any) => {
        console.error('On deleteLiveFile', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  startPipeline(id: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}${this.apiUrl}/${id}/start`, data).pipe(
      catchError((error: any) => {
        console.error('On startPipeline', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  stopPipeline(id: string): Observable<any> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}${this.apiUrl}/${id}/stop`, {}).pipe(
      catchError((error: any) => {
        console.error('On stopPipeline', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }
}
