import { Injectable } from '@angular/core'
import { EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita'

export interface LiveFilesState {
  liveFiles: any[]
}

export function createInitialState(): LiveFilesState {
  return {
    liveFiles: [],
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'liveFiles' })
export class LiveFilesStore extends Store<LiveFilesState> {
  constructor() {
    super(createInitialState())
  }
}
