/* eslint-disable @stylistic/js/max-len */
import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'

@Component({
  selector: 'cree8-team-members-card',
  styleUrls: ['./team-members.component.scss'],
  templateUrl: './team-members.component.html',
})
export class TeamMembersComponent implements OnInit {
  @Input() data: { info: string; available: boolean; desc: string }

  sanitizedIcon: any // Store sanitized SVG

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sanitizedIcon = this.sanitizer.bypassSecurityTrustHtml(this.icon)
  }

  onButtonClick = () => {
    this.router.navigate(['app/team'])
  }

  get icon() {
    return `
<svg xmlns="http://www.w3.org/2000/svg" width="151" height="118" viewBox="0 0 151 118" fill="none">
  <g clip-path="url(#clip0_7032_4196)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3334 116.593C24.3334 102.091 36.0052 90.3351 50.4031 90.3351H83.3334C97.7313 90.3351 109.403 102.091 109.403 116.593V122.121C109.403 124.41 107.56 126.267 105.287 126.267C103.014 126.267 101.171 124.41 101.171 122.121V116.593C101.171 106.671 93.1846 98.627 83.3334 98.627H50.4031C40.5519 98.627 32.5659 106.671 32.5659 116.593V122.121C32.5659 124.41 30.723 126.267 28.4497 126.267C26.1763 126.267 24.3334 124.41 24.3334 122.121V116.593Z" fill="url(#paint0_linear_7032_4196)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M66.8683 32.2919C57.017 32.2919 49.031 40.3354 49.031 50.2577C49.031 60.1799 57.017 68.2234 66.8683 68.2234C76.7195 68.2234 84.7055 60.1799 84.7055 50.2577C84.7055 40.3354 76.7195 32.2919 66.8683 32.2919ZM40.7985 50.2577C40.7985 35.756 52.4703 24 66.8683 24C81.2662 24 92.938 35.756 92.938 50.2577C92.938 64.7594 81.2662 76.5153 66.8683 76.5153C52.4703 76.5153 40.7985 64.7594 40.7985 50.2577Z" fill="url(#paint1_linear_7032_4196)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M112.147 94.4811C112.147 92.1913 113.99 90.3351 116.264 90.3351C130.662 90.3351 142.333 102.091 142.333 116.593V122.121C142.333 124.41 140.49 126.267 138.217 126.267C135.944 126.267 134.101 124.41 134.101 122.121V116.593C134.101 106.671 126.115 98.627 116.264 98.627C113.99 98.627 112.147 96.7708 112.147 94.4811Z" fill="url(#paint2_linear_7032_4196)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M95.6822 28.1459C95.6822 25.8562 97.5251 24 99.7985 24C114.196 24 125.868 35.756 125.868 50.2577C125.868 64.7594 114.196 76.5153 99.7985 76.5153C97.5251 76.5153 95.6822 74.6591 95.6822 72.3694C95.6822 70.0796 97.5251 68.2234 99.7985 68.2234C109.65 68.2234 117.636 60.1799 117.636 50.2577C117.636 40.3354 109.65 32.2919 99.7985 32.2919C97.5251 32.2919 95.6822 30.4357 95.6822 28.1459Z" fill="url(#paint3_linear_7032_4196)"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_7032_4196" x1="83.3334" y1="34.3332" x2="83.3334" y2="142.492" gradientUnits="userSpaceOnUse">
      <stop stop-color="#494E55"/>
      <stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_7032_4196" x1="83.3334" y1="34.3332" x2="83.3334" y2="142.492" gradientUnits="userSpaceOnUse">
      <stop stop-color="#494E55"/>
      <stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint2_linear_7032_4196" x1="83.3334" y1="34.3332" x2="83.3334" y2="142.492" gradientUnits="userSpaceOnUse">
      <stop stop-color="#494E55"/>
      <stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint3_linear_7032_4196" x1="83.3334" y1="34.3332" x2="83.3334" y2="142.492" gradientUnits="userSpaceOnUse">
      <stop stop-color="#494E55"/>
      <stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
    </linearGradient>
    <clipPath id="clip0_7032_4196">
      <path d="M0.333374 10C0.333374 4.47715 4.81053 0 10.3334 0H140.333C145.856 0 150.333 4.47715 150.333 10V108C150.333 113.523 145.856 118 140.333 118H10.3334C4.81053 118 0.333374 113.523 0.333374 108V10Z" fill="white"/>
    </clipPath>
  </defs>
</svg>
`
  }
}
