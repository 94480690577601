<ng-container *ngIf="project">
  @if (type === "grid") {
  <div class="projects-container" *ngIf="project">
    <div class="thumbnail-projects" (click)="detailProject(project)">
      <img [src]="projectCover" [alt]="project.name" />
      <div class="label-projects-container">


        <live-indicator *ngIf="projectLiveFile.hasLiveFile" [type]="projectLiveFile.isLive ? 'red' : 'grey'"
          [showLabel]="false"></live-indicator>

        <div class="label-project-type" [class.high-performance]="project.storage.highPerformance">
          <span>{{
            project.storage.highPerformance ? "High Performance" : "Standard"
            }}</span>
        </div>
      </div>
    </div>

    <div class="project-description">
      <div class="project-info">
        <span class="project-name body2" [title]="project.name" (click)="detailProject(project)">{{
          project.name
          }}</span>
        <span class="project-size body3">Size: {{ project.folderSize.gb }} GB</span>
      </div>

      <cree8-card-actions-list (closeDropdown)="onCloseDropdown(project)" (openDropdown)="onOpenDropdown(project)"
        [data]="project" [actionsList]="actionsList()" [type]="'project'" [active]="project.dropDownActive"
        (action)="onAction($event)"></cree8-card-actions-list>
    </div>
  </div>
  } @else {
  <div class="projects-container-list" [ngClass]="{ 'first-card': isFirst, 'last-card': isLast }" *ngIf="project">
    <div class="data" (click)="detailProject(project)">
      <div class="left">
        <div class="icon" [ngStyle]="{'background-image': 'url(' + projectCover + ')'}">
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Projects"></use>
          </svg>
        </div>
        <div class="project-name body2" [title]="project.name">{{ project.name }}</div>
      </div>
      <div class="right">
        <span class="project-size body3">Size: {{ project.folderSize.gb }} GB</span>
        <div class="options">

          <live-indicator *ngIf="projectLiveFile.hasLiveFile" [type]="projectLiveFile.isLive ? 'red' : 'grey'"
            [showLabel]="false"></live-indicator>

          <div class="label-project-type" [class.high-performance]="project.storage.highPerformance">
            <span>{{
              project.storage.highPerformance ? "High Performance" : "Standard"
              }}</span>
          </div>

        </div>
      </div>
    </div>
    <div class="project-actions">
      <cree8-card-actions-list (closeDropdown)="onCloseDropdown(project)" (openDropdown)="onOpenDropdown(project)"
        [data]="project" [actionsList]="actionsList()" [type]="'project'" [active]="project.dropDownActive"
        (action)="onAction($event)"></cree8-card-actions-list>
    </div>
  </div>

  }
</ng-container>
