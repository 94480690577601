// live-files.service.ts
import { Injectable } from '@angular/core'

import { LiveFilesStore } from './live-files.store'

@Injectable({ providedIn: 'root' })
export class LiveFilesService {
  constructor(private liveFilesStore: LiveFilesStore) {}

  // Load live files (for example, from an API)
  setLiveFiles(liveFiles: any[]) {
    this.liveFilesStore.update((state) => {
      const newState = { ...state, liveFiles }
      return newState
    })
  }
}
