import { Component, Inject, Input, OnInit } from '@angular/core'

import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'
import { PageSize, PageSizes, Pagination, SortBy } from 'src/app/common/types'
import { Project } from 'src/app/models/bebop.model'
import { UploadConstants } from 'src/app/services/rocket/classes/rocket-constants'
import { RocketService } from 'src/app/services/rocket/rocket.service'
import { UserService } from 'src/app/services/user.service'
import { UploaderService } from 'src/app/store/rocket/uploader/uploader.service'

import {
  RemoteFile,
  RemoteNavigator,
  RemoteNavTree,
  RocketSession,
  UiUploadFile,
} from '../../../common/classes/rocket-types'

export interface SelectRocketUploadPathAction {
  name: 'Cancel' | 'Close' | 'Select'
  path?: string
}

@Component({
  selector: 'bebop-select-rocket-upload-path',
  styleUrls: ['./select-rocket-upload-path.component.scss'],
  templateUrl: './select-rocket-upload-path.component.html',
})
export class SelectRocketUploadPathComponent implements OnInit, OnExternalModalClose {
  title = 'Select Upload Path'

  loading = false

  project: Project

  session: RocketSession<any, UiUploadFile>
  rocket: any

  search = ''
  projectName: string

  folderName: string = ''

  folders: RemoteFile[] = []
  filtered: RemoteFile[] = []

  isAddState = false
  errorFolder = false
  tooltipErrMsg = 'Folder name can only contain alphabets, numbers, a space and the following characters . , _ ( )'

  pageOptions: Pagination<RemoteFile> = {
    list: [],
    page: 1,
    size: <PageSize>PageSizes[1],
    sort: {
      reverse: false,
    },
    total: 0,
  }

  sortBy: SortBy = {}
  dirOnly = true
  searchEnabled = true

  noPagination = true

  navTree: RemoteNavTree[] = []

  navigator: RemoteNavigator = {
    path: '/',
    search: '',
    sort: [],
  }

  get user() {
    return this.userService.user
  }

  get selectedPath() {
    return this.navigator?.path || ''
  }

  constructor(
    public ref: ModalOverlayRef<SelectRocketUploadPathComponent, SelectRocketUploadPathAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any,
    private rocketService: RocketService,
    private uploaderService: UploaderService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.onError = this.onError.bind(this)
    this.sort = this.sort.bind(this)
  }

  ngOnInit(): void {
    this.title = this.data.title || this.title
    this.project = this.data.project
    this.session = this.data.session
    this.rocket = this.session?.rocket
    if (!this.project || !this.session || !this.rocket) {
      console.error('project/session/rocket is missing for upload rocket path selection')
      this.ref?.close()
      return
    }

    this.projectName = this.project.name || ''

    this.navTree = [
      {
        name: this.projectName,
        path: '/',
      },
    ]

    this.onRefresh()
  }

  toggleSort() {
    this.pageOptions.sort.reverse = !this.pageOptions.sort.reverse
    let folders = this.search ? this.filtered : this.folders
    folders?.sort(this.sort)

    this.onPageChange(this.pageOptions.page)
  }

  validateFolderName() {
    this.folderName = this.folderName ? this.folderName.trim() : ''
    if (!this.folderName || this.folderName.length > UploadConstants.MAX_FOLDER_NAME_LENGTH) {
      this.tooltipErrMsg = 'Folder name length should be between 1 and 50 characters'
      return false
    }

    let regEx = new RegExp('^([a-zA-Z0-9 ._()-]{1,50})$')

    if (!regEx.test(this.folderName)) {
      this.tooltipErrMsg =
        'Folder name can only contain alphabets, numbers, a space, and the following characters . , _ - ( )'
      return false
    }

    return true
  }

  onAddFolder() {
    if (!this.validateFolderName()) {
      this.onError({
        message: this.tooltipErrMsg,
      })

      return
    }

    this.navigator.search = this.search = ''
    this.isAddState = false
    let folderName = this.folderName
    let options = {
      newDirectory: folderName,
      projectId: this.project._id,
      recursive: true,
      relativePath: this.navigator.path,
      url: `${this.rocket.opts.target.protocol}://${this.rocket.opts.target.host}`,
      userId: this.user?._id,
    }

    this.loading = true
    this.rocket
      .createDirectory(
        {
          project: this.project,
        },
        options
      )
      .then((result: any) => {
        console.log('Folder created', folderName, result)

        if (result?.error?.description && result.error.description.indexOf('EEXIST') != -1) {
          this.toastService.show({
            text: 'Directory already exists!',
            type: 'warning',
          })
        }

        window.setTimeout(() => {
          this.loading = false
          let createdPath =
            this.navigator.path == '/' ? this.navigator.path + folderName : this.navigator.path + '/' + folderName
          createdPath = createdPath + '/'
          this.goToDirectory({
            name: this.folderName,
            path: createdPath,
          })
          this.folderName = ''
          this.onClearFolder()
        }, 16)
      })
      .catch(this.onError)
  }

  onClearFolder() {
    this.folderName = ''
    this.errorFolder = false
    this.isAddState = false
  }

  cancel() {
    this.loading = false
    this.ref.send({ name: 'Close' })
    this.ref.close()
  }

  select() {
    this.navigator.path = this.navigator.path.replace(/\/\/+/g, '/').trim()
    this.ref.send({ name: 'Select', path: this.selectedPath?.substring(1) })
    this.ref.close()
  }

  onSearch(s: string) {
    this.search = s

    let filtered = this.folders.filter((f) => {
      let regx = RegExp(this.search, 'i')
      if (f.name.search(regx) != -1) {
        return true
      }
      return false
    })

    filtered.sort(this.sort)
    this.filtered = filtered

    this.pageOptions.list = this.noPagination ? filtered : filtered?.slice(0, this.pageOptions.size)
    this.pageOptions.total = filtered.length
    this.pageOptions.page = 1
  }

  onAddTrigger() {
    this.isAddState = true
  }

  onKeyUpFolder(e: KeyboardEvent) {
    // console.log(this.folderName)
    this.errorFolder = !this.validateFolderName()

    if (e.key == 'Escape') {
      this.onClearFolder()
      return
    }

    if (!this.errorFolder && e.key == 'Enter') {
      this.onAddFolder()
      this.onClearFolder()
    }
  }

  onPageChange(s: number) {
    let folders = this.search ? this.filtered : this.folders

    this.pageOptions.list = this.noPagination
      ? folders
      : folders?.slice((s - 1) * this.pageOptions.size, s * this.pageOptions.size)
    this.pageOptions.page = s
  }

  selectFolder(f: RemoteFile) {
    // console.log(f)
    this.goToDirectory(f)
  }

  onRefresh() {
    this.onClearFolder()
    this.getDirectoryList()
  }

  onNavUp() {
    if (this.navTree.length <= 1) return

    this.navTree.pop()

    this.goToDirectory(this.navTree[this.navTree.length - 1])
  }

  onError(error: { message: string }) {
    window.setTimeout(() => {
      this.loading = false
      error = error || {
        message: 'Error while select directory',
      }
      console.error(error)
      this.rocketService.onNotification({
        message: error.message || 'Error getting details from server',
        type: 'error',
      })
    }, 16)
  }

  getDirectoryList() {
    this.loading = true
    let nav = this.navigator

    this.search = ''

    // unused ? - left over code from elastic search based browse?
    if (this.sortBy && Object.keys(this.sortBy).length) {
      nav.sort = [this.sortBy]
    } else {
      if (!this.sortBy) this.sortBy = {}

      // set default sort file name by asc
      this.sortBy.name = {
        order: 'asc',
      }
      nav.sort = [this.sortBy]
    }

    this.navTree = [
      {
        name: this.project.name,
        path: '/',
      },
    ]

    if (nav.path != '/') {
      let currentRoot = ''
      this.navTree = this.navTree.concat(
        nav.path
          .split('/')
          .filter((f) => f?.length)
          .map((p, idx) => {
            currentRoot = currentRoot + '/' + p
            return {
              name: p,
              path: currentRoot + '/',
            }
          })
      )
    }

    this.pageOptions.list = this.folders.slice(0, this.pageOptions.size)

    let rocket = this.session?.rocket

    let options = {
      limit: Number.MAX_SAFE_INTEGER,
      projectId: this.project._id,
      relativePath: nav.path,
      showFiles: false,
      skip: 0,
      userId: this.userService.id,
    }

    this.loading = true
    this.rocket
      .listDirectory(
        {
          project: this.project,
        },
        options
      )
      .then((result: { total: number; entries: RemoteFile[] }) => {
        window.setTimeout(() => {
          this.loading = false
          this.pageOptions.total = result.total
          this.folders = result.entries
          // Add relative path to result
          this.folders = this.folders.map((f) => {
            f.path = nav.path + f.name + '/'
            return f
          })
          this.folders.sort(this.sort)
          this.pageOptions.list = this.noPagination ? this.folders : this.folders.slice(0, this.pageOptions.size)
          this.pageOptions.page = 1
        }, 16)
      })
      .catch((e: any) => {
        window.setTimeout(() => {
          this.loading = false
          this.pageOptions.total = 0
          this.folders = []
          this.pageOptions.list = []
          this.pageOptions.page = 1
          this.onError(e)
        }, 16)
      })
  }

  sort(l: RemoteFile, r: RemoteFile) {
    return this.pageOptions.sort?.reverse ? r.name.localeCompare(l.name) : l.name.localeCompare(r.name)
  }

  goToDirectory(folder: RemoteFile) {
    let nav = this.navigator
    nav.path = folder.path
    this.search = ''

    this.pageOptions.page = 1

    this.getDirectoryList()
  }

  goBackDirectory(folder: RemoteFile) {
    let nav = this.navigator
    if (folder.path == nav.path) return
    nav.path = folder.path
    this.pageOptions.page = 1
    this.search = ''
    this.getDirectoryList()
  }
}
