import { Slide } from 'src/app/common/components/image-carousel/image-carousel.component'
import { ProgressBarType } from 'src/app/common/components/progress-bar/progress-bar.component'
import { SortBy } from 'src/app/common/types'
import { BebopLink, Organization, Project } from 'src/app/models/bebop.model'
import { FlexPin, UiFlexMeta, UiSelectable, UiWrapper } from 'src/app/models/ui.model'
import { MountOption } from 'src/app/store/flex/flex.select.store'

export const PartialVerificationThreshold = 0.2
export const OverallTransferSpeedSmoothFactor = 1 - 0.2
export const OverallTransferSpeedUnitIsInBits = false

export type RocketType = 'Upload' | 'Download' | 'Hot Folder' | 'CREE8 Link'

export type RocketConnectState = 'running' | 'reset' | 'timedout' | 'waiting' | 'error' | 'ping'
export type RocketProgressBarType = ProgressBarType | 'init'

export type HotFolderProgressState = 'Importing' | 'Exporting' | 'Updated' | 'Preparing'

export type StorageStatus = 'Normal' | 'Warning' | 'Alert'
export type ConnectionStatus = 'Poor' | 'Fair' | 'Good' | 'Excellent'

export type TransferState =
  | 'Uploading'
  | 'Downloading'
  | 'Waiting'
  | 'Verifying'
  | 'Paused'
  | 'Queued'
  | 'Error'
  | 'Stopped'
  | 'Completed'
  | 'Cancelled'
  | 'Streaming'

export const UiFileTypes = ['folder', 'image', 'document', 'video', 'audio', 'pdf', 'zip', 'others'] as const
export type UiFileType = (typeof UiFileTypes)[number]

export const UiFlexTypes = ['folder', 'link']
export type UiFlexType = (typeof UiFlexTypes)[number]
export type UiFlexSubType = 'default' | 'locked' | 'readonly' | 'readable-others'

export const UiVerificationStatuses = [
  'None',
  'Waiting',
  'Started',
  'Verifying',
  'Mismatch',
  'Error',
  'Verified',
  'Skipped',
  'Partially Verified',
] as const
export type UiVerificationStatus = (typeof UiVerificationStatuses)[number]

// IntegrityState is mapped with UiVerificationEnum
// there will be differences between IntegrityState and UiVerificationEnum
// (note CHECKING is 3 here, FAILURE/ERROR is 3 in IntegrityState)
export enum UiVerificationEnum {
  NONE = 0, // Init
  WAITING = 1,
  STARTED = 2,
  CHECKING = 3,
  MISMATCH = 4,
  ERROR = 5,
  VERIFIED = 6,
  SKIPPED = 7,
  PARTIAL_VERIFIED = 8,
}

export enum RocketChannel {
  Bebop = 2, // BEBOP_UPLOADER, BEBOP_DOWNLOADER
  Sync = 3,
  Link = 4,
}

export enum RocketStatus {
  Queued = 0,
  Started = 1,
  Uploading = 2,
  Paused = 3,
  Cancelled = 4,
  Failed = 5,
  Completed = 6,
  Downloading = 7,
  Staged = 8,
  Verifying = 9,
}

export enum RocketVerificationOptions {
  Skip,
  SkipOnlySmallFiles,
  FullVerification,
  PartialVerification,
}

export enum RocketIntegrityStatus {
  None = 0,
  Verified = 1,
  Error = 2,
  PartialVerified = 3,
  Skipped = 4,
}

export interface UiVerificationGrid {
  offset: number
  status: UiVerificationStatus
  message?: string
  hash?: string
  source?: any
}

export interface UiTransferFile {
  name: string
  path?: string
  // excluding filename
  directory?: string

  size?: number
  sizeStr?: string
  destPath?: string
  rootPath?: string

  extension?: string

  transferred?: number

  error?: boolean | string | Error

  completed?: boolean
  paused?: boolean

  transferState?: TransferState
  transferedPercent?: number
  transferedDate?: Date

  lastModifiedDate?: Date

  verificationStatus?: UiVerificationStatus
  fileType?: UiFileType
  type?: UiFileType
  badgeType?: UiFileType // above 2 are same as this one

  dir?: boolean

  verifyCollapse?: boolean
  verificationGrid?: UiVerificationGrid[]

  retryCount?: number
  recheckCount?: number

  errorReason?: string

  relativePath?: string

  printName?: string

  id?: string

  nonRecoverableError?: boolean

  errorMessage?: string

  retries?: number
  source?: any

  started?: Date
  finished?: Date
  file?: string // alias for name
  status?: RocketStatus

  verificationType?: 'Verified' | 'Partially Verified' | 'Skipped'
}

export interface UiUploadFile extends UiTransferFile {
  // add upload specifics here
  uploaded?: number
  growing?: boolean
}

export interface UiDownloadFile extends UiTransferFile {
  slides?: Slide[]
  userRoot?: string
  fileExt?: string
  projectId?: string
  depth?: number
  base?: string
  fullpath?: string
  project?: Project
  uniqueIdentifier?: string
  userId?: any
  displayName?: string
  direct?: boolean
  // add download specifics here

  destPath?: string
  destination?: string

  ui?: {
    dropDownActive?: boolean
  }
  mediainfo?: any
  media?: {
    isPreviewCollapsed?: boolean
    isInfoCollapsed?: boolean
    isTracksCollapsed?: boolean
    thumbnail?: string
    thumbnails?: any
    name?: string
    info: {
      [key: string]: any[]
      tracks: any[]
    }
    thumbsCount_?: number
    thumbsCount?: number
  }

  sizeBytes?: string
  retriable?: boolean
}

export interface UiHotfolder {
  selected?: boolean
  folderName: string
  basename?: string

  transferedPercent?: number
  progressState?: HotFolderProgressState

  folderSizeStr?: string

  showCardMenu?: boolean

  paths?: string[] // segments

  project?: Project

  source?: HotFolder

  storageUsed?: number
  storageStatus?: StorageStatus
  storageStatusMessage?: string
}

export interface UiBebopLink extends UiWrapper<BebopLink>, UiSelectable, UiFlexMeta {
  sizeStr?: string
  iname?: string
  storageUsed?: number
  storageStatus?: StorageStatus
  storageStatusMessage?: string
  connectionStatus?: ConnectionStatus
  pinnedFiles?: string[]
  pinnedList?: FlexPin[]
  statusDetails?: any
  cacheDetails?: any

  isLink?: boolean
  link?: string
  expires?: string
  notes?: string

  flexLandingTab?: string
}

export interface HotFolderTags {
  include?: {
    folders: string[]
    files: string[]
  }
  exclude?: {
    folders: string[]
    files: string[]
  }
}

export type HotFolderActionType = 'Create' | 'Edit'

export interface RocketTransferProgress<T extends UiTransferFile> {
  transferred?: number
  transferredBytes?: string
  size?: number
  sizeBytes?: string
  progressCount?: number
  verifying?: number
  error?: number
  completed?: number
  paused?: boolean

  pendingCount?: number
  completeList?: T[]
  errorList?: T[]
  progressList?: T[]
  verifyingList?: T[]
  nextList?: T[]
  queuedList?: T[]
  durationSeconds?: number
  durationReadable?: string
  transferRate?: string

  progress?: number
  total?: number

  completedBytes?: number
  totalCompleted?: number

  progressCountView?: string
  verifyingCountView?: string
  errorCountView?: string
  completedCountView?: string
  pendingCountView?: string
  liveCountView?: string
  queuedCountView?: string
}

export interface RocketSession<T, U extends UiTransferFile> {
  id: number
  rocket: T // UploadPlus | DownloadPlus
  entries: U[]
  newSession?: boolean
  abort?: boolean
  sessionID?: string
  metricsInfo?: {
    id?: string
    // TODO
    metrics?: any
    $modifiedList?: any
    [key: string]: any
  }
  sessionLastCleared?: number
  newSessionStarted?: number

  channel: RocketChannel

  overallProgress: Partial<RocketTransferProgress<U>>
  project?: Project // download may have multiple projects as its org based. use projects

  service?: RocketLifeCycle

  totalFiles?: number
  busy?: U[]

  remoteDirectory?: string
  rootPath?: string
  resetRootPath?: string

  checksumFileList?: any[]
  transferStatus?: RocketProgressBarType
  transferLabel?: string

  hotfolder?: HotFolder
  hotfolderProgressState?: HotFolderProgressState

  // download specifics
  diskUsage?: RocketDiskUsage
  rocketDownloadView?: RocketDownloadView
  browseView?: RocketDownloadBrowseView
  organization?: Organization
  downloadQueue?: any[]
  downloadQueueMap?: any
  projects?: Project[] // we may or may not track this info here for downloads - check life cycle's addProject function

  // for drop links
  link?: BebopLink
  machineId?: string

  durationInSeconds?: number
  speed?: string
}

export enum RocketDownloadView {
  browser = 'browser',
  download = 'download',
  queue = 'queue',
  settings = 'settings',
}

export enum RocketDownloadBrowseView {
  ListView = 0,
  GalleryView = 1,
}

export interface RocketDiskUsage {
  low: boolean
  available: string
  total: string
}

export interface Teardown {
  teardown(): void
}

export interface HotFolder {
  path?: string
  one_way?: boolean
  absolute_path?: boolean
  file_tags?: string[]
  folder_tags?: string[]
  blacklist_file_tags?: string[]
  blacklist_folder_tags?: string[]

  date_created?: Date | number

  // not for network
  project?: Project
  service?: RocketLifeCycle
  rocket?: any
  session?: RocketSession<any, UiUploadFile>
}

export interface HotFolderUserProjectCache {
  hotFolders?: HotFolder[]
  project?: Project
}
export interface HotFolderUserCache {
  [key: string]: HotFolderUserProjectCache
}

export interface HotFolderCache {
  [key: string]: HotFolderUserCache
}

export interface RocketLifeCycle {
  hasActiveTransfers(): boolean
  onLogin(): void
  onLogout(): void
}

export interface RemoteNavTree {
  path: string
  name: string
  root?: boolean
}

export interface RemoteFile {
  name?: string
  displayName?: string
  path?: string
  dir?: boolean
  _id?: string
  size?: number
  mtime?: number
}

export interface RemoteNavigator {
  path: string
  sort: SortBy[]
  search: string

  depth?: number
  ext?: string
  maxSize?: number
  startDate?: Date
  endDate?: Date
  startDateTs?: number
  endDateTs?: number
  data?: any
}
export interface TransferOverview {
  total?: number
  totalBytes?: string
  transferred?: number
  transferredBytes?: string
  progress?: number
  label?: string

  paused?: boolean
}

export interface MachineInfo {
  arch?: string
  platform?: string
  release?: string
  type?: string
  cpus?: {
    total?: number
    model?: string
    speed?: number
  }
  sysUser?: string
}

export type BrowseView = 'Gallery' | 'List'

export interface RocketBusy {
  isBusy<T extends UiTransferFile>(session: RocketSession<any, T>): boolean
}
