import { Component, Input } from '@angular/core'

@Component({
  selector: 'cree8-indicator-env',
  styleUrl: './cree8-indicator-env.component.scss',
  templateUrl: './cree8-indicator-env.component.html',
})
export class Cree8IndicatorEnvComponent {
  @Input() identifier: 'beta' | 'dev' = 'beta'
}
