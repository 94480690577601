<div class="container">
  <div class="ai">
    <span class="display3 title-ai fade-in-up">No AI Training</span>
    <span class="body1 description-ai fade-in-up">
      CREE8 will never use any of your content to train an AI or machine
      learning model. Your intellectual property remains yours, always.
    </span>
    <a
      title="Learn more"
      alt="Learn more"
      href="#"
      (click)="openLearnMore()"
      class="body1fade-in-up"
      >Learn more</a
    >
  </div>
</div>
