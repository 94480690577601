<ng-container *ngIf="file">
  @if (type === "grid") {
  <div class="projects-container" *ngIf="file">
    <div class="thumbnail-projects"
      [class.no-hover]="notAllowPlayer()"
      [class.no-flex]="file.type === 'image' && file.media?.thumbsCount > 0"
      (click)="onAction({ action: 'detail', data: file })">

      <div class="thumbnail-image" >
        <img *ngIf="file.media?.thumbnail && file.media?.thumbsCount > 1"
          [src]="'data:image/png;base64,' + file.media.thumbnails[0].img" width="100%" height="auto" />

        <img *ngIf="file.media?.thumbnail && file.media?.thumbsCount === 1"
          [src]="'data:image/png;base64,' + file.media.thumbnail" width="100%" height="auto" />
      </div>

      <div class="thumbnail-icon-container">
        <div class="thumbnail-icon" [class.active]="viewSupportedVideoType()">
          @if (file.type === 'folder') {
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Folder_Single"></use>
          </svg>
          } @else if (viewSupportedVideoType()) {
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Play"></use>
          </svg>
          } @else if (viewSupportedAudioType()) {
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Audio"></use>
          </svg>
          } @else if (file.type === 'image'){
            <span></span>
          }
          @else {
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Generic_File"></use>
          </svg>
          }
        </div>
      </div>

    </div>
    <div class="project-description">
      <div class="project-info">
        <span class="body2 file-name" [title]="file.name" (click)="onAction({ action: 'detail', data: file })">{{
          file.name }}</span>
        <span class="desc body3" [hidden]="!file?.sizeBytes">Size: {{ file.sizeBytes }}</span>
      </div>
      <cree8-card-actions-list (closeDropdown)="onCloseDropdown(file)" (openDropdown)="onOpenDropdown(file)"
        [data]="file" [actionsList]="actionsList()" [type]="file.type === 'folder' ? 'folder' : 'file'"
        [active]="file.ui?.dropDownActive" (action)="onAction($event)"></cree8-card-actions-list>
    </div>
    <!-- disable right now; using default tooltip -->
    <!-- <div class="tooltip-icon">
      <svg class="ico" viewBox="0 0 12 6">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Arrow_Up_Polygon"></use>
      </svg>
    </div>
    <div class="tooltip-file">
      <span class="captions"> {{ file.name }} </span>
    </div> -->
  </div>
  } @else {
  <div class="projects-container-list" *ngIf="file" [ngClass]="{ 'first-card': isFirst, 'last-card': isLast }">
    <div class="data" (click)="onAction({ action: 'detail', data: file })">
      <div class="left">
        <div class="thumbnail-projects">
          <div class="thumbnail-icon" [class.active]="file.type === 'video'">
            @if (file.type === 'folder') {
            <svg class="ico" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Folder_Single"></use>
            </svg>
            } @else if (file.type === 'video') {
            <svg class="ico" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Play"></use>
            </svg>
            } @else if (file.type === 'audio') {
            <svg class="ico" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Audio"></use>
            </svg>
            } @else if (file.type === 'image'){
            <svg class="ico" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Generic_File"></use>
            </svg>
            }
            @else {
            <svg class="ico" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Generic_File"></use>
            </svg>
            }
          </div>

        </div>
        <span class="body2 file-name" [title]="file.name">{{
          file.name }}</span>
      </div>
      <div class="right">
        <span class="desc body3" [hidden]="!file?.sizeBytes">Size: {{ file.sizeBytes }}</span>

      </div>
    </div>
    <div class="options">
      <cree8-card-actions-list (closeDropdown)="onCloseDropdown(file)" (openDropdown)="onOpenDropdown(file)" [data]="file"
      [actionsList]="actionsList()" [type]="file.type === 'folder' ? 'folder' : 'file'"
      [active]="file.ui?.dropDownActive" (action)="onAction($event)"></cree8-card-actions-list>
    </div>
  </div>
  }
</ng-container>
