/* eslint-disable @stylistic/js/max-len */
import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'

@Component({
  selector: 'cree8-workstations-card',
  styleUrls: ['./workstations.component.scss'],
  templateUrl: './workstations.component.html',
})
export class WorkStationsComponent implements OnInit {
  @Input() data: { info: string; available: boolean; desc: string }

  sanitizedIcon: any // Store sanitized SVG

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sanitizedIcon = this.sanitizer.bypassSecurityTrustHtml(this.icon)
  }

  onButtonClick = () => {
    this.router.navigate(['app/workstations'])
  }

  get icon() {
    return `
<svg xmlns="http://www.w3.org/2000/svg" width="151" height="118" viewBox="0 0 151 118" fill="none">
  <g clip-path="url(#clip0_7032_5385)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M128.337 24H44.9966C37.0766 24 30.6666 30.41 30.6666 38.33V90.42C30.6666 98.33 37.0766 104.74 44.9966 104.74H69.7366V115.91C64.7266 116.35 59.7366 116.99 54.7666 117.82C52.6366 118.18 51.1966 120.19 51.5566 122.32C51.9166 124.45 53.9266 125.89 56.0566 125.53C76.3266 122.13 97.0066 122.13 117.277 125.53C119.407 125.89 121.417 124.45 121.777 122.32C122.137 120.19 120.697 118.18 118.567 117.82C113.597 116.99 108.607 116.35 103.597 115.91V104.74H128.337C136.257 104.74 142.667 98.33 142.667 90.42V38.33C142.667 30.41 136.257 24 128.337 24ZM95.7866 115.38C89.7066 115.09 83.6266 115.09 77.5466 115.38V104.74H95.7866V115.38ZM134.857 90.42C134.857 94.01 131.937 96.93 128.337 96.93H44.9966C41.3966 96.93 38.4766 94.01 38.4766 90.42V38.33C38.4766 34.73 41.3966 31.81 44.9966 31.81H128.337C131.937 31.81 134.857 34.73 134.857 38.33V90.42Z" fill="url(#paint0_linear_7032_5385)"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_7032_5385" x1="86.6666" y1="24" x2="86.6666" y2="137.195" gradientUnits="userSpaceOnUse">
      <stop stop-color="#494E55"/>
      <stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
    </linearGradient>
    <clipPath id="clip0_7032_5385">
      <path d="M0.666626 10C0.666626 4.47715 5.14378 0 10.6666 0H140.667C146.189 0 150.667 4.47715 150.667 10V108C150.667 113.523 146.189 118 140.667 118H10.6666C5.14378 118 0.666626 113.523 0.666626 108V10Z" fill="white"/>
    </clipPath>
  </defs>
</svg>
`
  }
}
