import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

import { uniqBy } from 'lodash'
import { catchError, of, Subject, Subscription, switchMap, takeUntil, throwError } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { IConfirmationModal } from 'src/app/common/components/confirmation-modal/confirmation-modal.component'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { Slide } from 'src/app/common/components/image-carousel/image-carousel.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { ToastType } from 'src/app/common/components/toast/toast.types'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { PageSize, PageSizes, Pagination } from 'src/app/common/types'
import { ShareLinkComponent } from 'src/app/components/projects/components/share-link/share-link.component'
import {
  RemoteFile,
  RemoteNavigator,
  RemoteNavTree,
  RocketSession,
  UiDownloadFile,
} from 'src/app/components/rocket/common/classes/rocket-types'
import {
  FileDetailsRocketDownloadAction,
  FileDetailsRocketDownloadComponent,
} from 'src/app/components/rocket/downloads/modals/file-details-rocket-download/file-details-rocket-download.component'
import { Organization, Project } from 'src/app/models/bebop.model'
import { IProjectDetail, IStorage } from 'src/app/models/projects.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { ProjectsService } from 'src/app/services/projects.service'
import { DownloadConstants } from 'src/app/services/rocket/classes/rocket-constants'
import { DownloaderLifecycleService } from 'src/app/services/rocket/download/downloader-lifecycle.service'
import { ElasticSearchService } from 'src/app/services/rocket/elastic-search.service'
import { FileBadgeService } from 'src/app/services/rocket/file-badge.service'
import { RocketService } from 'src/app/services/rocket/rocket.service'
import { UserService } from 'src/app/services/user.service'
import { ProjectsQuery } from 'src/app/store/projects/projects.query'
import { ProjectsService as ProjectsStoreService } from 'src/app/store/projects/projects.service'
import { DownloaderQuery } from 'src/app/store/rocket/downloader/downloader.query'
import { DownloaderService } from 'src/app/store/rocket/downloader/downloader.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'cree8-project-files-view',
  styleUrl: './project-files-view.component.scss',
  templateUrl: './project-files-view.component.html',
})
export class ProjectFilesViewComponent implements OnInit, OnDestroy {
  @ViewChild('shareLinkComponent') shareLinkComponent!: ShareLinkComponent
  files: UiDownloadFile[] = []
  filtered: UiDownloadFile[] = []
  isLoading = true
  streamUrl: string = ''
  selectedFile: UiDownloadFile
  toggleFile: UiDownloadFile

  pageOptions: Pagination = {
    list: [],
    page: 1,
    size: <PageSize>PageSizes[1],
    sort: {
      reverse: false,
    },
    total: 0,
  }

  navigator: RemoteNavigator

  project: Project
  organization: Organization

  search = ''
  service: any

  get projectName() {
    return this.project?.name ?? ''
  }

  ref: ModalOverlayRef<FileDetailsRocketDownloadComponent, FileDetailsRocketDownloadAction>

  viewType = 'grid'

  protected _pageLabel: string = ''

  get pageLabel() {
    return this._pageLabel
  }

  get pageSizes() {
    return PageSizes
  }

  navTree: RemoteNavTree[] = []
  selectedProject$: Subscription
  session$: Subscription
  session: RocketSession<any, UiDownloadFile>
  private destroy$ = new Subject<void>()

  modalVideoTitle = 'Video Player'
  modalImageTitle = 'Image Viewer'
  modalAudioTitle = 'Audio Player'

  modalSetting: Cree8Modal = {
    counting: 0,
    current: 0,
    title: '',
    type: 'close',
  }

  confirmationDeleteModal: IConfirmationModal = {
    description: '',
    loading: false,
    title: '',
    type: 'danger',
  }

  modalDeleteActive = false
  MODAL_TITLE_SHARE_LINK = 'Create CREE8 Share Link'
  MODAL_TITLE_NOTES_LINK = 'Create CREE8 Review Link'
  MODAL_TITLE_RECEIVE_LINK = 'Create CREE8 Receive Link'

  mediaInfo = {}
  mediaInfoLoading = false

  constructor(
    private util: BebopClientUtilsService,
    private modalService: ComponentModalService,
    private userService: UserService,
    private uiQuery: UIQuery,
    private rocketService: RocketService,
    private fileBadgeService: FileBadgeService,
    private elasticService: ElasticSearchService,
    private lcService: DownloaderLifecycleService,
    private downloaderQuery: DownloaderQuery,
    private downloaderService: DownloaderService,
    private domSanitizer: DomSanitizer,
    private toastService: ToastService,
    private projectsStoreService: ProjectsStoreService,
    private projectsService: ProjectsService,
    private projectsQuery: ProjectsQuery
  ) {
    this.onNotification = this.onNotification.bind(this)
    this.onError = this.onError.bind(this)
    this.sort = this.sort.bind(this)
    this.toUiFiles = this.toUiFiles.bind(this)
  }

  ngOnInit(): void {
    this.isLoading = true
    let org = this.uiQuery.getSelectedOrgValue()
    this.session = this.lcService.newRocketSession(org)
    this.projectsQuery
      .getSelectedProject()
      .pipe(takeUntil(this.destroy$))
      .subscribe((project) => {
        if (!project) return

        this.project = project
        this.downloaderService.update({ selectedProject: { ...this.project } })

        this.downloaderService.selectProjectAndPod({
          selectedPod: this.project.pod,
          selectedProject: this.project,
        })
        this.navigator = this.downloaderQuery.getValue().browseNav[this.project._id]
        this.lcService.addProject(this.project)
        this.files = this.navigator.data ?? []
        // this.updateNav()
        this.goToRootFolder()
        // this.onPageChange(1)
        //this.browseFiles()
      })

    this.projectsQuery
      .getViewTypeFiles()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.viewType = response
      })
  }

  ngOnDestroy(): void {
    this.ref?.close()
    ;[this.selectedProject$, this.session$].forEach((e) => e?.unsubscribe())
    this.destroy$.next()
    this.destroy$.complete()
  }

  get noFiles() {
    return this.files?.length == 0
  }

  onFileDownload(file: UiDownloadFile) {
    if (file.type === 'folder') return

    // Return an observable for the file download
    this.rocketService
      .downloadFile(file)
      .pipe(
        switchMap((res) => {
          const a = document.createElement('a')
          a.href = res
          a.download = `${file.name}.${file.fileExt}`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          return of(null) // Return an observable
        }),
        catchError((error) => {
          this.onError(error) // Handle the error appropriately
          return throwError(error) // Rethrow the error if needed
        })
      )
      .subscribe()
  }

  onPageChange(s: number, concat?: boolean) {
    let files = this.search ? this.filtered : this.files

    // Append new items to the existing list instead of replacing
    if (concat) {
      const newItems = files?.slice((s - 1) * this.pageOptions.size, s * this.pageOptions.size)
      this.pageOptions.list = this.pageOptions.list.concat(newItems || [])
    } else {
      this.pageOptions.list = files?.slice((s - 1) * this.pageOptions.size, s * this.pageOptions.size)
    }

    this.pageOptions.page = s
    this.pageOptions.total = files?.length

    this._pageLabel = [
      (this.pageOptions.page - 1) * this.pageOptions.size + 1,
      '-',
      Math.min(this.pageOptions.total, this.pageOptions.page * this.pageOptions.size),
      'from',
      this.pageOptions.total,
    ].join(' ')

    this.loadMediaInfo()
    this.fetchStats()
  }

  onPageSizeChange(e: Event) {
    let p = <HTMLInputElement>e.target
    let _size = this.pageOptions.size
    this.pageOptions.size = <PageSize>(<unknown>p.value)
    if (_size == this.pageOptions.size) return
    this.onPageChange(this.pageOptions.page)
  }

  onSearchFilename(s: string) {
    this.search = s?.trim?.() ?? s

    let filtered = s?.length
      ? this.files.filter((f) => {
          let regx = RegExp(this.search, 'i')
          if (f.name.search(regx) != -1) {
            return true
          }
          return false
        })
      : this.files.slice(0)

    filtered.sort(this.sort)
    this.filtered = filtered

    this.pageOptions.list = filtered?.slice(0, this.pageOptions.size)
    this.pageOptions.total = filtered.length
    this.pageOptions.page = 1
  }

  onFileInfo(file: UiDownloadFile) {
    this.ref = this.modalService.open<FileDetailsRocketDownloadComponent, FileDetailsRocketDownloadAction>(
      FileDetailsRocketDownloadComponent,
      {
        backdropClass: 'bebop-modal-backdrop-transparent',
        data: {
          filename: file.name,
          ...file.media,
        },
        hasBackdrop: true,
      },
      {
        hasBackdropClick: true,
        hasEscapeClose: true,
        isCentered: false,
        position: {
          right: '0px',
          top: '86px',
        },
      }
    )

    this.ref.once().subscribe((e) => {
      console.log(e)
    })
  }

  refresh() {
    this.files = []
    this.isLoading = true
    this.browseFiles()
  }

  getFileThumbnails(file: UiDownloadFile): Slide[] {
    let media = file?.media

    if (!media) return []
    let thumbnails = media.thumbnails || (media.thumbnail ? [{ img: media.thumbnail }] : [])

    return thumbnails.map(
      (o) =>
        <Slide>{
          src: this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + o.img),
        }
    )
  }

  onOpenDropdown(file: UiDownloadFile) {
    if (!file.ui) file.ui = { dropDownActive: true }
    else file.ui.dropDownActive = true
  }

  onCloseDropdown(file: UiDownloadFile) {
    if (!file.ui) file.ui = { dropDownActive: false }
    else file.ui.dropDownActive = false
  }

  clearFilter() {
    this.search = ''
    this.pageOptions.page = 1
    this.browseFiles()
  }

  resetNavTree() {
    this.navTree = [
      {
        name: this.project.name,
        path: '/',
        root: true,
      },
    ]

    this.projectsStoreService.setNavTree(this.navTree)
  }

  updateNav() {
    this.navTree = [
      {
        name: this.project.name,
        path: '/',
        root: true,
      },
    ]

    let nav = this.navigator

    if (nav.path != '/') {
      let currentRoot = ''
      this.navTree = this.navTree.concat(
        nav.path
          .split('/')
          .filter((f) => f?.length)
          .map((p, idx) => {
            currentRoot = currentRoot + '/' + p
            return {
              name: p,
              path: currentRoot + '/',
            }
          })
      )
    }

    this.projectsStoreService.setNavTree(this.navTree)
  }

  osOneFlexPath() {
    if (this.project?.solutions?.osOneBlock) {
      return this.project?.organization?.osOneBlockRootPath || ''
    } else if (this.project?.solutions?.osOneFlex) {
      return this.project?.organization?.flexMountCredential?.credentials?.lucid?.root_mount || ''
    } else {
      return ''
    }
  }

  browseFiles() {
    console.log('browseFiles')
    if (!this.project || !this.session?.rocket) {
      console.log('no project or rocket')
      return
    }

    let nav = this.navigator
    this.search = ''

    if (!this.navigator) return

    if (!this.navigator.sort?.length) {
      this.navigator.sort = [
        {
          name: {
            order: 'asc',
          },
        },
      ]
    }

    this.updateNav()

    let rocket = this.session?.rocket

    let options = {
      limit: Number.MAX_SAFE_INTEGER,
      projectId: this.project._id,
      relativePath: nav.path,
      showFiles: true,
      skip: 0,
      userId: this.userService.id, // TODO - pulling more data in one shot ? any better option?
      /**
         Server side pagniation settings
        skip: (this.pageOptions.page - 1) * this.pageOptions.size,
        limit: this.pageOptions.size,
         */
    }

    this.pageOptions.list = this.files.slice(0, this.pageOptions.size)
    this.downloaderService.setLoading()

    // this.isLoading = true
    rocket
      .listDirectory(
        {
          project: this.project,
        },
        options
      )
      .then((result: { total: number; entries: RemoteFile[] }) => {
        if (this.project?._id != options?.projectId) return
        window.setTimeout(() => {
          this.downloaderService.setConnected()

          let project = this.project
          let storage = project?.storage

          this.pageOptions.total = result.total
          let rpath = nav.path.replace(/^\//, '')
          this.files = result?.entries?.map(this.toUiFiles) || []
          // Add relative path to result
          this.files = this.files.map((f) => {
            f.userRoot = nav.path
            f.direct = true

            f.fileExt = f.dir ? '' : f.name.replace(/^.*\./, '')
            f.project = project
            f.projectId = project._id
            f.depth = nav.depth
            f.base = [
              storage.transferServerMount,
              this.osOneFlexPath(),
              storage.directories.projects,
              project.folderName,
            ]
              .filter((x) => x)
              .join('/')
            if (nav.path === '/') {
              f.relativePath = f.name
            } else {
              f.relativePath = rpath + '/' + f.name
            }
            f.fullpath = f.base + '/' + f.relativePath

            let fileType = f.dir ? 'folder' : this.fileBadgeService.getFileType(f.name)
            f.type = fileType

            f.sizeBytes = ''
            f.userId = this.userService?.id
            f.slides = []
            f.displayName =
              f.name.length > DownloadConstants.MAX_NAME_DISPLAY_LENGTH
                ? f.name.substring(0, DownloadConstants.MAX_NAME_DISPLAY_LENGTH) + '…'
                : f.name
            return f
          })

          this.files.sort(this.sort)
          // this.isLoading = false
          this.navigator.data = this.files

          this.onPageChange(1)
        }, 16)
      })
      .catch((e: Error) => {
        if (this.project?._id != options?.projectId) return
        this.onError(e)
      })
  }

  loadMediaInfo() {
    console.log('loadMediaInfo')
    if (!this.project || !this.session?.rocket) return

    let project = this.project

    let options = {
      fullpaths: this.pageOptions.list.map((f) => f.fullpath),
      path: this.navigator.path,
      projectId: this.project._id,
      size: this.pageOptions.list.length,
      userId: this.userService.id,
    }

    this.elasticService.listDirectory(options).subscribe((result) => {
      if (result.error) {
        console.error('list directory error', result.error)
        if (!this.project || project._id != this.project._id) return
        this.onError(new Error(result.error?.msg || 'load media info error'))
        return
      }

      if (!this.project || project._id != this.project._id) return

      let records = result.hits
      if (!records || records.length == 0) return

      let lookup = this.pageOptions.list.reduce((acc, f) => {
        acc[f.fullpath] = f
        return acc
      }, {})

      // $scope.service.addProject(project);
      let files = records.map(this.lcService.mapEsRecordToUI)
      files = uniqBy(files, 'name')
      let matchedFiles = []
      files.forEach((f) => {
        let file = lookup[f.fullpath]

        if (!file) return
        // some information could be stale
        file.checksum = f.checksum
        if (f.dir) {
          file.size = f.size
          file.sizeBytes = f.sizeBytes
        }
        file.modified = f.modified
        file.mediainfo = f.mediainfo
        file.media = this.buildMedia(file)
        if (file.media && file.media.thumbsCount) {
          file.media.thumbsCount_ = file.media.thumbsCount
          file.media.thumbsCount = 1
        }

        // mp4 like files can be audio too.
        if (file.media && file.media.thumbnails) {
          file.type = 'video'
        }
        matchedFiles.push(file)
      })

      matchedFiles.forEach((f) => {
        if (f.media && f.media.thumbsCount_) {
          f.media.thumbsCount = f.media.thumbsCount_
          delete f.media.thumbsCount_
        }
      })
    })
  }

  fetchStats() {
    if (!this.project || !this.session?.rocket) return

    let project = this.project
    let options = {
      relativePaths: this.pageOptions.list.map((f) => f.relativePath),
    }
    // this.isLoading = true
    this.session.rocket
      .fetchStats(
        {
          project: project,
        },
        options
      )
      .then((result) => {
        if (!this.project || project._id != this.project._id || !result) return

        setTimeout(() => {
          let lookup = this.pageOptions.list.reduce((acc, f) => {
            acc[f.fullpath] = f
            return acc
          }, {})

          let entries = result.entries || []
          entries.forEach((f) => {
            let file = lookup[f.name]
            if (!file) return
            file.modified = f.mtime ? new Date(f.mtime) : null
            // let [, mmm, dd, yyyy] = new Date(f.mtime).toDateString().split(' ')
            // file.modified = `${dd} ${mmm} ${yyyy}`
            if (file.dir && !f.size) return
            file.size = f.size
            file.sizeBytes = this.util.readablizeBytes(f.size)
          })
          this.isLoading = false
        }, 16)
      })
      .catch((e) => {
        console.error('fetch stats error', e)
        if (!this.project || project._id != this.project._id) return

        this.onError(e)
        this.isLoading = false
      })
  }

  sort(l: UiDownloadFile, r: UiDownloadFile) {
    return this.pageOptions.sort?.reverse ? r.name.localeCompare(l.name) : l.name.localeCompare(r.name)
  }

  toggleSort() {
    this.pageOptions.sort.reverse = !this.pageOptions.sort.reverse
    let files = this.search ? this.filtered : this.files
    files?.sort(this.sort)

    this.onPageChange(this.pageOptions.page)
  }

  buildMedia(file: UiDownloadFile) {
    let mediainfo = file.mediainfo
    file.slides = []
    if (!mediainfo) return

    if (file.media) {
      file.media.isPreviewCollapsed = file.media.isInfoCollapsed = file.media.isTracksCollapsed = true
      if (file.media.thumbnail) file.media.isPreviewCollapsed = false
      else file.media.isInfoCollapsed = false
      return file.media
    }

    let media: any = (file.media = {
      info: {
        general: [['FILE SIZE', file.sizeBytes]],
        tracks: [],
      },
      name: file.name,
      thumbnail: mediainfo.thumbnail,
    })
    let ignoreKeys = ['complete_name', 'rawText', 'file_size', 'file_size_bytes']
    let keys = Object.keys(mediainfo)
    for (let idx = 0; idx < keys.length; idx++) {
      let key = keys[idx]
      if (ignoreKeys.indexOf(key) !== -1 || key.startsWith('thumbnail')) continue
      let value = mediainfo[key]
      if (typeof value === 'string') {
        media.info.general.push([key.replace(/_/g, ' ').toUpperCase(), value])
      } else if (key === 'tracks' && value.length) {
        media.info.tracks = value.map((obj) => {
          return Object.keys(obj).map((o) => {
            return [o.replace(/_/g, ' ').toUpperCase(), obj[o]]
          })
        })
      }
    }
    media.isPreviewCollapsed = media.isInfoCollapsed = media.isTracksCollapsed = true
    if (media.thumbnail) {
      media.isPreviewCollapsed = false
      media.thumbsCount = mediainfo.thumbsCount = mediainfo.thumbsCount || 1

      if (media.thumbnail && !this.util.isValidBase64(media.thumbnail)) {
        delete media.thumbnail
      } else if (mediainfo.thumbsCount > 1) {
        media.thumbnails = [
          {
            img: media.thumbnail,
          },
        ]
        for (let idx = 1; idx < mediainfo.thumbsCount; idx++) {
          let img = mediainfo[`thumbnail${idx}`]
          if (img)
            media.thumbnails.push({
              img,
            })
        }
      }
    } else {
      media.isInfoCollapsed = false
    }

    file.slides = this.getFileThumbnails(file)

    return media
  }

  onNotification(event: { message?: string; statusMessage?: string; statusCode?: number; type: ToastType }) {
    setTimeout(() => {
      let msg = event.message || event.statusMessage || event.statusCode?.toString?.() || 'Server Error'

      if (event.type == 'error') console.error('Error Notification:', msg, event)

      this.toastService.show({
        text: msg,
        type: event.type,
      })
    }, 16)
  }

  onError(error: { message: string; msg?: string }) {
    window.setTimeout(() => {
      this.downloaderService.setConnected()
      error = error || {
        message: 'Error while select directory',
      }
      console.error(error)
      this.onNotification({
        message: error.message || error.msg || 'Error getting details from server',
        type: 'error',
      })
    }, 16)
  }

  toUiFiles(r: RemoteFile): UiDownloadFile {
    return {
      name: r.name,
      ...r,
    }
  }

  goToDirectory(file: UiDownloadFile) {
    let root = (<any>file)?.root
    if (!file.dir) return
    let nav = this.navigator
    let path = root ? file.path : `/${file.relativePath}`

    if (nav.path == path) return
    nav.path = path
    this.search = ''

    this.pageOptions.page = 1
    this.files = []
    this.isLoading = true
    this.browseFiles()
  }

  goBackDirectory(f: RemoteNavTree) {
    let nav = this.navigator
    let path = f.path

    if (nav.path == path) return
    nav.path = path
    this.search = ''

    console.log(nav, 'f')

    this.pageOptions.page = 1
    this.files = []
    this.isLoading = true
    this.browseFiles()
  }

  goToRootFolder() {
    // let nav = this.navigator
    /// nav.path = '/'
    this.search = ''
    this.pageOptions.page = 1
    this.files = []
    this.isLoading = true
    this.browseFiles()
  }

  selectFolder(f: UiDownloadFile) {
    this.goToDirectory(f)
  }

  mappingProjectCard(project: Project) {
    return {
      _id: project._id,
      date_created: '',
      folderSize: project.folderSize,
      fromBucket: '',
      is_active: false,
      is_template: false,
      isLive: false,
      name: project.name,
      organization: project.organization,
      pod: project.pod,
      preferredLetter: project.preferredLetter,
      solutions: project.solutions,
      storage: {
        storageSize: project.storage?.storageSize || {
          allocated: 0,
          current: 0,
          percentUsed: 0,
          storageWarningSent: null,
        },
      } as IStorage,
      sync_active: project.sync_active,
      syncDirection: '',
    } as IProjectDetail
  }

  createFolder(folderName: string): Promise<any> {
    let rocket = this.session?.rocket
    console.log(rocket.opts, 'rocket.opts')

    let options = {
      newDirectory: folderName,
      projectId: this.project._id,
      recursive: true,
      relativePath: this.navigator.path,
      userId: this.userService.id,
    }

    return rocket.createFolderOnProject(
      {
        project: this.project,
      },
      options
    )
  }

  onActionFile(props: { type: string; data: any }, file: any) {
    if (file.type === 'folder' && props.type === 'detail') {
      this.goToDirectory(file)
      return
    }

    if (
      (file.type === 'video' && props.type === 'detail') ||
      (file.type === 'image' && props.type === 'detail') ||
      (file.type === 'audio' && props.type === 'detail')
    ) {
      this.selectedFile = file
      this.modalSetting.title = this.viewSupportedVideoType(file)
        ? this.modalVideoTitle
        : this.viewSupportedAudioType(file)
          ? this.modalAudioTitle
          : this.modalImageTitle
      this.getStreamMedia(file)
      return
    }

    if (props.type === 'media-info') {
      this.onFileInfo(file)
      return
    }

    if (props.type === 'download-file') {
      this.onFileDownload(file)
      return
    }

    if (props.type === 'delete') {
      this.selectedFile = file
      this.showDeleteModal()
      return
    }

    if (props.type === 'share' || props.type === 'notes') {
      this.selectedFile = file
      this.modalSetting.title = props.type === 'share' ? this.MODAL_TITLE_SHARE_LINK : this.MODAL_TITLE_NOTES_LINK
      this.modalSetting.type = 'confirmation'

      if (props.type === 'notes') {
        this.mediaEncoding()
      }

      return
    }

    if (props.type === 'receive-link') {
      this.selectedFile = file
      this.modalSetting.title = this.MODAL_TITLE_RECEIVE_LINK
      this.modalSetting.type = 'confirmation'
      return
    }

    if (props.type === 'toggle') {
      this.toggleFile = file
      return
    }
  }

  getStreamMedia(file: any) {
    this.selectedFile = file
    this.rocketService
      .downloadFile(file)
      .pipe(
        catchError((error) => {
          this.onError(error)
          return throwError(error)
        })
      )
      .subscribe({
        error: (err) => {
          console.error('Error downloading video:', err)
        },
        next: (res) => {
          this.streamUrl = res
        },
      })
  }

  onCloseModal() {
    this.modalSetting.title = ''
    this.streamUrl = ''
  }

  onSubmitModal() {
    console.log('onSubmitModal', this.modalSetting.title)
    if (
      this.modalSetting.title === this.MODAL_TITLE_SHARE_LINK ||
      this.modalSetting.title === this.MODAL_TITLE_RECEIVE_LINK ||
      this.modalSetting.title === this.MODAL_TITLE_NOTES_LINK
    ) {
      this.submitShareLink()
      return
    }
  }

  private submitShareLink() {
    const step = this.shareLinkComponent.step
    if (step === 1) {
      this.shareLinkComponent.generateDropLink()
    } else if (step === 2) {
      const statusProtect = this.shareLinkComponent.statusProtect()
      if (this.shareLinkComponent.showPassword) {
        this.shareLinkComponent.updateLinkProtect()
        return
      }

      if (statusProtect === 'PASSWORD') {
        this.shareLinkComponent.showPasswordProtect()
      } else {
        this.shareLinkComponent.updateLinkProtect()
      }
    } else {
      this.shareLinkComponent.copyLink()
      this.modalSetting.title = ''
    }
  }

  onSuccessCreateLink() {
    this.modalSetting.labelConfirm = 'COPY LINK'
    this.modalSetting.labelCancel = 'CLOSE'
  }

  showDeleteModal() {
    this.modalDeleteActive = true
    if (this.selectedFile.type === 'folder') {
      this.confirmationDeleteModal.title = 'Delete This Folder?'
      this.confirmationDeleteModal.description =
        'Are you sure you want to delete this folder? All files within it will be permanently removed. This action cannot be undone.'
    } else {
      this.confirmationDeleteModal.title = 'Delete This File?'
      this.confirmationDeleteModal.description =
        'Are you sure you want to delete this file? It will be permanently removed. This action cannot be undone.'
    }
  }

  deleteFolderOrFile() {
    this.confirmationDeleteModal.loading = true
    this.rocketService
      .deleteFile(this.selectedFile, this.session?.machineId, this.session?.link?.link)
      .then((res) => {
        console.log(res, 'delete folder or file')
        this.toastService.show({
          text: `${this.selectedFile.type === 'folder' ? 'Folder' : 'File'} deleted successfully`,
          type: 'success',
        })
        this.refresh()
      })
      .catch((err) => {
        this.toastService.show({
          text: `${this.selectedFile.type === 'folder' ? 'Folder' : 'File'} delete failed`,
          type: 'error',
        })
      })
      .finally(() => {
        this.confirmationDeleteModal.loading = false
        this.modalDeleteActive = false
      })
  }

  mediaEncoding() {
    this.mediaInfo = {}
    this.mediaInfoLoading = true
    this.rocketService
      .mediaEncoding(this.selectedFile, this.session?.machineId, this.session?.link?.link)
      .then((res) => {
        console.log(res, 'get encoding')
        this.mediaInfo = res
      })
      .catch((err) => {
        this.toastService.show({
          text: `Get encoding failed`,
          type: 'error',
        })
      })
      .finally(() => {
        this.mediaInfoLoading = false
      })
  }

  private share() {}

  loadMoreFiles() {
    if (this.isLoading || this.pageOptions.list.length === this.pageOptions.total) return
    this.isLoading = true
    this.pageOptions.page += 1
    this.onPageChange(this.pageOptions.page, true)
  }

  extensionAudio(file: UiDownloadFile) {
    const extension = file.name.toLowerCase().split('.').pop()
    return `audio/${extension}`
  }

  extensionVideo(file: UiDownloadFile) {
    const extension = file.name.toLowerCase().split('.').pop()
    return `video/${extension}`
  }

  viewSupportedVideoType(file: UiDownloadFile) {
    const ext = file.name.toLowerCase().split('.').pop()
    return ['mp4', 'mov'].indexOf(ext) != -1
  }

  viewSupportedAudioType(file: UiDownloadFile) {
    const ext = file.name.toLowerCase().split('.').pop()
    return ['mp3', 'wav', 'aac', 'aacp', 'flac', 'caf'].indexOf(ext) != -1
  }
}
