<div class="section">
  <div class="container">
    <div class="content-two-side">
      <div class="content-section fade-in-up" [class.right]="right">
        <span class="display2 title-section"> {{ title }} </span>
        <span class="body1 description-section">
          {{ description }}
        </span>
      </div>
      <img
        *ngIf="image.src"
        [src]="image.src"
        [srcset]="
          image.src.replace('.png', '@1x.png') +
          ' 1x, ' +
          image.src.replace('.png', '@2x.png') +
          ' 2x, ' +
          image.src.replace('.png', '@3x.png') +
          ' 3x'
        "
        [width]="image.width"
        [height]="image.height"
        [alt]="image.alt"
        loading="lazy"
        class="fade-in-up"
      />
    </div>
  </div>
</div>
