import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'

import { Subject, takeUntil } from 'rxjs'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { TableAction } from 'src/app/components/shared/table-actions/table-actions.component'
import { BebopLink, Organization, Project, User } from 'src/app/models/bebop.model'
import { IPayloadUpdateStatus } from 'src/app/models/projects-links.model'
import { ProjectsLinksQuery } from 'src/app/store/projects/projects-links.query'
import { ProjectsLinksService } from 'src/app/store/projects/projects-links.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { UIQuery } from 'src/app/store/ui/ui.query'

import { ExpirationStatus, LinkExpirationComponent } from './link-expiration/link-expiration.component'
import { LinkProtectComponent, ProtectStatus } from './link-protect/link-protect.component'
import { LinkSettingsComponent } from './link-settings/link-settings.component'

@Component({
  selector: 'cree8-links-projects',
  styleUrl: './links-projects.component.scss',
  templateUrl: './links-projects.component.html',
})
export class LinksProjectsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() projectId: string | undefined = undefined
  private _destroy$ = new Subject()
  private user: User
  private organization: Organization
  links: BebopLink[] = []
  loading = true
  linksTypeControl = new FormControl(8)
  disableButton: boolean = false
  MODAL_TITLE_PROTECT = 'Protect CREE8 Link'
  MODAL_TITLE_STATUS = 'CREE8 Link Stats'
  MODAL_TITLE_EXPIRATION = 'Set Link Expiration Date'
  MODAL_TITLE_SETTINGS = 'CREE8 Link Settings'

  modal: Cree8Modal = {
    counting: 0,
    current: 0,
    loading: false,
    title: '-',
    type: 'confirmation',
  } as Cree8Modal

  modalActive = false
  selectedLink: BebopLink = {} as BebopLink
  project: Project | undefined = undefined

  @ViewChild('linkProtect') linkProtect: LinkProtectComponent
  @ViewChild('linkExpiration') linkExpiration: LinkExpirationComponent
  @ViewChild('linkSettings') linkSettings: LinkSettingsComponent

  constructor(
    private projectsLinksService: ProjectsLinksService,
    private projectsLinksQuery: ProjectsLinksQuery,
    private toastService: ToastService,
    private sessionQuery: SessionQuery,
    private uiQuery: UIQuery,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.projectId) {
      this.projectsLinksService.resetStore()
    }

    this.linksTypeControl.valueChanges.subscribe((value) => {
      this.filterByType(value)
    })

    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this._destroy$))
      .subscribe((org) => {
        // Ensure org exists
        if (!org) {
          return
        }

        if (this.organization && this.organization._id !== org._id && this.projectId) {
          this.router.navigate(['/app/projects'])
        }

        this.user = this.sessionQuery.getUserValue()
        this.organization = org

        this.links = []
        this.loadLinks()
      })

    this.projectsLinksQuery.getLoadingStatus().subscribe((res) => {
      this.loading = res
    })
  }

  ngAfterViewInit(): void {
    this.renderData()
  }

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.complete()
  }

  loadLinks() {
    this.projectsLinksService
      .getListLinksProjects(this.projectId, this.organization._id)
      .pipe(takeUntil(this._destroy$))
      .subscribe()
  }

  renderData() {
    this.projectsLinksQuery
      .getListLinksProjects()
      .pipe(takeUntil(this._destroy$))
      .subscribe((data) => {
        this.links = data
      })
  }

  filterByType(value: number) {
    this.projectsLinksService.filterByType(value)
  }

  toggleStatus(status: 'expired' | 'deactivated') {
    this.projectsLinksService.toggleStatus(status)
  }

  activedLinks(status: boolean, data: BebopLink) {
    const payload: IPayloadUpdateStatus = {
      _id: data._id,
      linkType: data.linkType,
      organization: data.organization._id,
      projectId: data.project._id,
    }

    this.projectsLinksService
      .activedLinks(status, payload)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        error: (err) => {
          this.toastService.show({
            text: 'CREE8 Link status has been failed',
            type: 'error',
          })
        },
        next: (res: { success: boolean }) => {
          if (res.success) {
            this.toastService.show({
              text: 'CREE8 Link status has been updated',
              type: 'success',
            })

            this.loadLinks()
          }
        },
      })
  }

  onAction(action: TableAction, data: BebopLink) {
    this.selectedLink = data
    this.resetModal()

    switch (action) {
      case 'protect':
        this.modal.title = this.MODAL_TITLE_PROTECT
        this.modal.type = 'confirmation'
        this.onChangeOption(this.selectedLink.protected || 'NONE')
        this.modalActive = true
        break
      case 'status':
        this.modal.title = this.MODAL_TITLE_STATUS
        this.modal.type = 'close'
        this.modalActive = true
        break
      case 'copy':
        this.copyLink()
        break
      case 'expiry':
        this.modal.title = this.MODAL_TITLE_EXPIRATION
        this.modal.type = 'confirmation'
        this.modalActive = true
        break
      case 'settings':
        this.modal.title = this.MODAL_TITLE_SETTINGS
        this.modal.labelConfirm = 'SAVE'
        this.modal.labelCancel = 'CANCEL'
        this.modal.type = 'confirmation'
        this.modalActive = true
        break
      default:
        break
    }
  }

  onCancelModal(value: boolean) {
    this.modalActive = false
  }

  onNext(value: boolean) {
    if (this.modal.title === 'Protect CREE8 Link') {
      const status = this.linkProtect.protectValue
      if (status === 'PASSWORD') {
        this.passwordForm()
      } else {
        this.linkProtect.onUpdateProtect()
      }

      return
    }

    if (this.modal.title === 'Set Link Expiration Date') {
      this.linkExpiration.onUpdateExpiration()
      return
    }

    if (this.modal.title === 'CREE8 Link Settings') {
      this.linkSettings.onUpdateSettings()
      return
    }
  }

  private passwordForm() {
    if (this.modal.current === 1) {
      this.linkProtect.showPasswordForm = true
      this.modal.current = 2
    } else {
      this.linkProtect.onUpdateProtect()
    }
  }

  private copyLink() {
    navigator.clipboard
      .writeText(this.selectedLink.link)
      .then(() => {
        this.toastService.show({
          text: 'CREE8 Link has been copied to clipboard',
          type: 'success',
        })
      })
      .catch((err) => {
        this.toastService.show({
          text: 'Failed to copy text: ',
          type: 'error',
        })
      })
  }

  onStatusUpdateProtect(value: ProtectStatus) {
    if (value === 'loading') {
      this.modal.loading = true
      return
    }

    if (value === 'disable') {
      this.modal.disableNext = true
      return
    }

    if (value === 'enable') {
      this.modal.disableNext = false
      return
    }

    this.modal.loading = false
    this.modalActive = false
    // this.resetModal();
    this.loadLinks()
  }

  onStatusUpdateExpiration(value: ExpirationStatus) {
    if (value === 'loading') {
      this.modal.loading = true
      return
    }

    if (value === 'success') {
      this.modal.loading = false
      this.modalActive = false
      this.loadLinks()
      return
    }

    this.modal.loading = false
    this.modalActive = false
  }

  onStatusUpdateSettings(value: ExpirationStatus) {
    if (value === 'loading') {
      this.modal.loading = true
      return
    }

    if (value === 'success') {
      this.modal.loading = false
      this.modalActive = false
      this.loadLinks()
      return
    }

    this.modal.loading = false
    this.modalActive = false
  }

  onChangeOption(value: string) {
    if (value === 'PASSWORD') {
      this.modal.counting = 2
      this.modal.current = 1
      this.modal.labelConfirm = 'NEXT'
    } else {
      this.modal.counting = 0
      this.modal.current = 0
      this.modal.labelConfirm = 'SAVE'
    }
  }

  resetModal() {
    this.modal = {
      counting: 0,
      current: 0,
      loading: false,
      title: '-',
      type: 'confirmation',
    }
  }

  sorting(sorting: string) {
    this.links = []
    this.projectsLinksService.sortData(sorting)
    this.loadLinks()
  }
}
