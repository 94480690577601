import { Component, OnDestroy, OnInit } from '@angular/core'

import { interval, Subject, switchMap, takeUntil } from 'rxjs'
import { LiveFilesService } from 'src/app/services/live-files.service'
import { LiveFilesService as LiveFilesStoreService } from 'src/app/store/projects/live-files.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
@Component({
  selector: 'bebop-projects',
  styleUrl: './projects.component.scss',
  templateUrl: './projects.component.html',
})
export class ProjectsComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject()

  private readonly PIPELINE_REFRESH_INTERVAL = 30 * 1000

  constructor(
    private liveFilesStoreService: LiveFilesStoreService,
    private liveFilesService: LiveFilesService,
    private uiQuery: UIQuery
  ) {}

  ngOnInit(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this._destroy$))
      .subscribe((org) => {
        if (!org) {
          return
        }

        // Call the API once on load
        this.liveFilesService
          .getLiveFiles({})
          .pipe(takeUntil(this._destroy$))
          .subscribe((res) => {
            const liveFiles = res.liveFiles || []
            this.liveFilesStoreService.setLiveFiles(liveFiles)

            // Start the interval only if live files are found
            if (liveFiles.length > 0) {
              this.startLiveFilesInterval()
            }
          })
      })
  }

  private startLiveFilesInterval(): void {
    interval(this.PIPELINE_REFRESH_INTERVAL)
      .pipe(
        takeUntil(this._destroy$),
        switchMap(() => this.liveFilesService.getLiveFiles({}))
      )
      .subscribe((res) => {
        const liveFiles = res.liveFiles || []
        if (liveFiles.length > 0) {
          this.liveFilesStoreService.setLiveFiles(liveFiles)
        }
      })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.complete()
  }
}
