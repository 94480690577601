import { Component } from '@angular/core';
declare let gtag: Function


@Component({
  selector: 'cree8-website-cta-book',
  templateUrl: './website-cta-book.component.html',
})
export class WebsiteCtaBookComponent {

  showCalendly = false

  bookDemo() {
    gtag('event', 'DEMO_BUTTON', {
      'event_category': 'Form Submission',
      'event_label': 'Lead Form',
      'value': 1
    })

    gtag('event', 'conversion', {
      'send_to': 'AW-11321015870/D2P4CO2-pNMZEL78o5Yq',
      'event_category': 'Demo Button Click',
      'event_label': 'Book appointments'
    })

    this.showCalendly = true
    console.log('showCalendly', this.showCalendly)
    return
  }
}
