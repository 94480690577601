import { Injectable, NgZone } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { IsActiveMatchOptions, Router } from '@angular/router'

import { BehaviorSubject, Subscription } from 'rxjs'
import {
  AnnouncementKey,
  Entitlement,
  Organization,
  OrgEntitlements,
  User,
  UserLevelPermission,
} from 'src/app/models/bebop.model'
import {
  AnnouncementsResponse,
  EntitlementsResponse,
  IntercomSettingResponse,
  LoginResponse,
  OrganizationsResponse,
  OrgWatermarkResponse,
  PermissionsResponse,
  PodsResponse,
} from 'src/app/models/response.model'
import { SessionQuery } from 'src/app/store/session/session.query'
import { SessionService } from 'src/app/store/session/session.service'

import { ModalOverlayRef } from '../common/classes/modal-overlay-ref'
import {
  WarningPromptAction,
  WarningPromptComponent,
  WarningPromptPayload,
} from '../common/components/modals/warning-prompt/warning-prompt.component'
import { NotificationService } from '../common/components/notification/notification.service'
import { NotificationData } from '../common/components/notification/notification.types'
import { ToastService } from '../common/components/toast/toast.service'
import { ComponentModalService, CustomOverlayConfig } from '../common/services/component-modal.service'
import { LogoutAction, LogoutComponent } from '../components/logout/modals/logout/logout.component'
import {
  LogoutPromptAction,
  LogoutPromptComponent,
} from '../components/logout/modals/logout-prompt/logout-prompt.component'
import {
  ClientUpdateInstallAction,
  ClientUpdateInstallComponent,
} from '../components/modals/client-update-install/client-update-install.component'
import { FlexQuery } from '../store/flex/flex.query'
import { FlexService } from '../store/flex/flex.service'
import { UIQuery } from '../store/ui/ui.query'
import { UIService } from '../store/ui/ui.service'
import { NavPermissions } from '../store/ui/ui.store'
import { WorkstationQuery } from '../store/workstation/workstation.query'
import { WorkstationService } from '../store/workstation/workstation.service'

import { RocketLifecycleService } from './rocket/rocket-lifecycle.service'
import { BebopClientUtilsService } from './bebop-client-utils.service'
import { BebopConfigService } from './bebop-config.service'
import { AutoUpdateState, ElectronService } from './electron.service'
import { IntercomService } from './intercom.service'
import { UserService } from './user.service'
import { UserSettingsService } from './user-settings.service'
type CallbackFunction = (arg1?: any, arg2?: any) => void
// From chrome dev tool color suggestion
export const COLOR_PALETTE = Object.freeze([
  '#2196f3',
  '#3f51b5',
  '#673ab7',
  '#9c27b0',
  '#e91e63',
  '#f44336',
  '#00bcd4',
  '#03a9f4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#9e9e9e',
  '#607d8b',
])
@Injectable({
  providedIn: 'root',
})
export class MainService {
  private user: User
  // private pendingTransfers: any[]
  navPermissions: NavPermissions
  private entitlements: OrgEntitlements
  private selectedOrg: Organization

  loggedIn: boolean

  networkConnected = true

  newQuickRelease = false

  logout$: ModalOverlayRef<LogoutComponent, LogoutAction>

  _appEvents$ = new BehaviorSubject<string>(null)
  selectedPod$: Subscription

  osoneRegions = []
  osoneAmis = []
  wsGroupsAndConfig = { groups: [{ name: 'default' }], workstationConfig: { nextNumber: 0 } }

  constructor(
    private domSanitizer: DomSanitizer,
    private uiQuery: UIQuery,
    private uiService: UIService,
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private config: BebopConfigService,
    private userSettings: UserSettingsService,
    private electronService: ElectronService,
    private rocketLcService: RocketLifecycleService,
    private wservice: WorkstationService,
    private flexService: FlexService,
    private workstationQuery: WorkstationQuery,
    private flexQuery: FlexQuery,
    private modalService: ComponentModalService,
    private notificationService: NotificationService,
    private toastService: ToastService,
    private ngZone: NgZone,
    private router: Router,
    private clientUtils: BebopClientUtilsService,
    private userService: UserService,
    private intercomService: IntercomService
  ) {
    this.teardownAppInternal = this.teardownAppInternal.bind(this)
    this.bootstrap()

    this.notificationService.getNotifications((ns: NotificationData[]) => {
      this.updateBadgeCount()
    })

    setTimeout(() => {
      this.getAnouncements()
      // retry as in old client - every 5 mins
      setInterval(() => this.getAnouncements(), 300000)
    }, 2000)

    this.bindWindowEvents()
  }

  // app level events if required
  appEvents(cb: (ev: string) => void) {
    return this._appEvents$.subscribe(cb)
  }

  private bootstrap() {
    this.sessionQuery.getLoggedIn().subscribe((loggedIn) => {
      if (this.loggedIn && !loggedIn) {
        // Already logged in, status changed to logged out
        this.teardownApp()
        return
      }

      this.loggedIn = loggedIn

      if (!this.loggedIn) return

      this.electronService.notifyMain('user.login')
      this.user = this.sessionQuery.getUserValue()
      this.sessionService.getIntercomSettings().subscribe((settings: IntercomSettingResponse) => {
        if (settings) {
          this.intercomService.initIntercom({
            api_base: settings.INTERCOM_API_BASE,
            app_id: settings.INTERCOM_APP_ID,
            created_at: Date.now(),
            email: this.user.email,
            name: this.user.name,
            user_id: this.user._id,
          })
        }
        if (typeof window['pendo'] !== 'undefined') {
          this.sessionQuery.getOrganizations().subscribe((orgs) => {
            const orgNames = orgs.map((org) => org.displayName || org.name).join(', ')
            window['pendo'].initialize({
              account: {
                accountName: this.user.name,
                id: this.user._id,
                organization: orgNames,
              },
              visitor: {
                email: this.user.email,
                id: this.user._id,
              },
            })
          })
        } else {
          console.log('Pendo initizlization failed')
        }
      })

      this.initializeApp()
    })

    this.uiQuery.getSelectedOrg().subscribe((org) => {
      if (org) {
        if (!this.entitlements[org._id]) this.goToDashboard()
        this.uiService.setSelectedEntitlement(this.entitlements[org._id])
        this.navPermissions = { ...this.uiQuery.getNavPermissionsValue() }
        this.setNavPermissions(this.entitlements[org._id])
        this.getPods(org._id)
        this.onChangeOrganization(org)
      }
    })

    window.addEventListener('beforeunload', (e) => {
      if (!this.user || !this.rocketLcService.hasActiveTransfers()) return

      e.returnValue = false
      this.exitWindow()
    })

    this.listenOnMainProcess()
  }

  updateNetworkOnlineStatus() {
    const onLine = navigator.onLine ? 'online' : 'offline'
    console.log('Internet connection status', onLine)
    this._appEvents$.next(`network:${onLine}`)

    if (/*this.user && */ this.networkConnected != navigator.onLine) {
      this.toastService.show({
        text: navigator.onLine ? 'Your Internet Connection is back' : 'Please check your Internet Connection.',
        type: navigator.onLine ? 'success' : 'error',
      })
    }

    this.networkConnected = navigator.onLine
  }

  getInternetOnlineStatus() {
    return navigator.onLine
  }

  getWorkstationRegions() {
    let options = {
      currentPage: 1,
      deactivated: false,
      maxSize: 10,
      pageSize: 50,
      sort: 'name',
      webStoreSignupEnabled: true,
    }
    this.wservice.getWorkstationRegions(options).subscribe({
      error: function (e) {},
      next: function (response) {
        this.osoneRegions = response || []
      },
    })
  }

  // TODO: Use same iframed view as on MCP for now as the workstation wizard flow is to be redesigned
  iframeMessageHandler(event) {
    let that = this
    let frame = document.getElementById('launch_workstation') as HTMLIFrameElement
    if (event.data.type == 'get') {
      switch (event.data.for) {
        case 'regions': {
          let options = {
            currentPage: 1,
            deactivated: false,
            maxSize: 10,
            pageSize: 50,
            sort: 'name',
            webStoreSignupEnabled: true,
          }
          this.wservice.getWorkstationRegions(options).subscribe({
            error: function (e) {},
            next: function (response) {
              let message = { for: 'regions', regions: response || [] }
              frame.contentWindow.postMessage(message, '*')
            },
          })

          break
        }
        case 'amis': {
          let options = {
            processorType: event.data.data.processorType,
            region: event.data.data.region,
          }
          this.wservice.getWebStoreAmi(options).subscribe({
            error: function (err) {
              this.toastService.show({
                text: 'Get machine image failed',
                type: 'error',
              })
            },
            next: function (response) {
              if (response && response.data && response.data.error) {
                this.toastService.show({
                  text: 'Get machine image failed',
                  type: 'error',
                })
                return
              }

              this.osoneAmis = response

              let message = { amis: this.osoneAmis, for: 'amis' }
              frame.contentWindow.postMessage(message, '*')
            },
          })
          break
        }
        case 'wsGroupsAndConfig': {
          let options = {
            pod: event.data.data.pod,
            region: event.data.data.region,
          }
          this.wservice.getOsoneWorkstationGroupsAndConfig(options).subscribe({
            error: function (err) {
              this.toastService.show({
                text: 'Get machine image failed',
                type: 'error',
              })
            },
            next: function (response) {
              this.wsGroupsAndConfig = response || {}

              this.wsGroupsAndConfig.groups = (this.wsGroupsAndConfig.groups &&
                this.wsGroupsAndConfig.groups.length &&
                this.wsGroupsAndConfig.groups) || [{ name: 'default' }]

              this.wsGroupsAndConfig.workstationConfig = this.wsGroupsAndConfig.workstationConfig || {
                nextNumber: 0,
              }

              let message = { for: 'wsGroupsAndConfig', wsGroupsAndConfig: this.wsGroupsAndConfig }
              frame.contentWindow.postMessage(message, '*')
            },
          })

          break
        }
        default:
          break
      }
    }

    if (event.data.type == 'notification' && event.data.for == 'launch_workstation') {
      let that = this
      this.toastService.show({
        text: 'Launching workstation',
        type: 'success',
      })

      this.wservice
        .launchVMOSOne({
          ami: event.data.data.ami,
          organization: this.selectedOrg._id,
          region: event.data.data.region,
          workstation: {
            diskSize: event.data.data.workstationDetails.diskSize,
            displayName: event.data.data.workstationDetails.workstationDisplayName,
            instanceType: event.data.data.workstationDetails.instanceType,
            name: event.data.data.workstationDetails.workstationName,
          },
          wsGroup: event.data.data.wsGroup,
        })
        .subscribe({
          error: function () {
            that.toastService.show({
              text: 'Workstation Launch Failed',
              type: 'error',
            })
          },
          next: function (response) {
            if (response.error) {
              that.toastService.show({
                text: 'Workstation Launch Failed',
                type: 'error',
              })
            } else {
              that.toastService.show({
                text: 'Workstation Launched',
                type: 'success',
              })
            }
          },
        })
    }
  }

  bindWindowEvents() {
    this.updateNetworkOnlineStatus = this.updateNetworkOnlineStatus.bind(this)
    window.addEventListener('online', this.updateNetworkOnlineStatus)
    window.addEventListener('offline', this.updateNetworkOnlineStatus)

    this.iframeMessageHandler = this.iframeMessageHandler.bind(this)
    window.addEventListener('message', this.iframeMessageHandler)
  }

  listenOnMainProcess() {
    this.electronService.registerHookFor('message:before-quit', (event: any, res: any) => {
      // this.exitWindow()
      this.loggedIn = false
      this.sessionService.triggerLogout()
      this.teardownApp()
    })

    this.electronService.registerHookFor('message:app-updated', (event: any, res: any) => {
      this.onAppUpdated()
    })

    this.electronService.registerHookFor('bebop-patch-update', (event: any, res: any) => {
      const name: AutoUpdateState = res?.name

      // growl only for non error, after login case
      if (this.loggedIn && res.name != 'error') {
        this.toastService.show({
          text: res.message,
          type: res.name == 'update-downloaded' ? 'success' : res.name == 'error' ? 'error' : 'info',
        })
      }

      if (name == 'update-downloaded') {
        if (!this.loggedIn) {
          setTimeout(() => this.electronService.applyPatchRelease(), 2000)
          this.newQuickRelease = false
          return
        }

        this.newQuickRelease = true
        this.onAppUpdated(true, false, true)
      }
    })

    this.electronService.registerHookFor('bebop-auto-update', (event: any, res: any) => {
      const name: AutoUpdateState = res?.name

      const opts: IsActiveMatchOptions = {
        fragment: 'exact',
        matrixParams: 'exact',
        paths: 'exact',
        queryParams: 'exact',
      }

      if (this.router.isActive('/update', opts)) return

      if (name == 'update-downloaded') {
        this.onAppUpdated()
      }
    })
  }

  async onNotifyAppUpdated(patch = false) {
    this.ngZone.run(() => {
      const ref = this.modalService.open<ClientUpdateInstallComponent, ClientUpdateInstallAction>(
        ClientUpdateInstallComponent,
        {
          data: {
            patch,
          },
          hasBackdrop: true,
        },
        {
          hasBackdropClick: true,
          hasEscapeClose: true,
          isCentered: true,
        }
      )

      function update() {
        if (this.newQuickRelease) {
          this.electronService.applyPatchRelease()
          this.newQuickRelease = false
          return
        }

        // no need to remove notification - quit is sufficient
        this.electronService.quitAndInstall()
      }

      ref.once().subscribe((e) => {
        if (e.name == 'Install') {
          if (this.loggedIn) {
            this.teardownApp(() => {
              update()
            })
            return
          }

          update()
        }
      })
    })

    return false
  }

  updateBadgeCount() {
    this.electronService.updateBadgeCount(this.notificationService.count())
  }

  hasNotification(data: NotificationData) {
    return this.notificationService.hasNotification(data)
  }

  // Notification service wrapper - always call this
  addNotification(data: NotificationData, opt: { system: boolean; beep: boolean } = { beep: true, system: false }) {
    if (!data) return

    let notify: any
    const onClose = data.onClose

    // close wrapper which closes system notification
    data.onClose = () => {
      opt?.system && notify?.close?.()
      return onClose?.() || Promise.resolve(false)
    }

    data.afterClose = () => {
      opt?.system && notify?.close?.()
    }

    const ret = this.notificationService.addNotification(data)
    if (!ret) return

    if (opt?.system) {
      notify = new window.Notification('Update', { body: data.text })
      notify.onclick = () => {
        console.log('User clicked from system notification', data)
        data?.onSelect?.()
      }

      notify.onclose = () => (notify = null)
      notify.onerror = (...args: any) => console.log('System notification error', args)
    }

    if (opt?.beep) {
      this.electronService.beep()
    }

    this.updateBadgeCount()
  }

  removeNotification(data: NotificationData) {
    this.notificationService.removeNotification(data)
    this.updateBadgeCount()
  }

  removeAllNotifications() {
    this.notificationService.removeAll()
    this.updateBadgeCount()
  }

  async onAppUpdated(patch = false, system = true, beep = true) {
    this.addNotification(
      {
        closeable: false,
        id: 'message:app-updated',
        onSelect: () => this.onNotifyAppUpdated(patch),
        priority: 100,
        text: `New app ${patch ? 'patch ' : ''}version available! ${patch ? 'Apply Now' : 'Install Now'}`,
        type: 'info',
      },
      { beep, system }
    )

    if (
      this.rocketLcService.hasActiveTransfers() ||
      this.workstationQuery.getActiveWorkstationSessionsValue() > 0 ||
      this.flexQuery.getMountsValue()?.length > 0
    ) {
      return
    }

    // relaunch modal
    await this.onNotifyAppUpdated(patch)
  }

  private exitWindow() {
    const fn = this.electronService.prepareExitWindow()

    // avoid multiple teardown
    this.loggedIn = false

    this.sessionService.triggerLogout()

    this.teardownApp(fn)

    setTimeout(fn, 15 * 1000)
  }

  private initializeApp() {
    this.refreshSession()
    this.getUserImage()
    this.getAnouncements()
    this.startPowerSaveBlocker()
    this.flexService.onLogIn()
    this.rocketLcService.onLogin()
    this.wservice.onLogin(this)
  }

  get canNavToFlex() {
    // let permissions = this.uiQuery.getNavPermissionsValue()
    // let userPermissions = this.uiQuery.getUserPermissionsValue()
    // return userPermissions.links || permissions.flex || this.userService?.userEntitlement?.canAccessFlexLinks()

    const org = this.uiQuery.getSelectedOrgValue()
    const permissions = this.entitlements?.[org?._id] ?? {}

    return permissions.ACCESS_FLEX_CLIENT_MOUNT || permissions.FLEX_CLIENT_MOUNT || permissions.ACCESS_BEBOP_FLEX_LINKS
  }

  private teardownAppInternal(cb?: CallbackFunction) {
    // TODO

    if (this.rocketLcService.hasActiveTransfers()) {
      setTimeout(() => {
        this.teardownAppInternal(cb)
      }, 2000)
    } else {
      this.logout$?.close()
      this.electronService.sendIpcRenderer('user.out')
      this.sessionService.forceLogout()
      this.uiService.forceLogout()

      if (this.newQuickRelease) {
        this.electronService.applyPatchRelease()
        this.newQuickRelease = false
        return
      }

      this.getAnouncements(() => {
        cb?.()
      })
    }
  }

  private teardownApp(cb?: CallbackFunction) {
    this.logout$ = this.modalService.open<LogoutComponent, LogoutAction>(
      LogoutComponent,
      {
        data: {},
        hasBackdrop: true,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: true,
      }
    )

    this.rocketLcService.onLogout()
    this.flexService.onLogout()
    this.wservice.onLogout()

    // TODO - stop launched workstations ?

    this.router.navigate(['/'], {})
    this.teardownAppInternal(cb)
  }

  promptBeforeTeardown() {
    return new Promise<boolean>((r) => {
      const ev = this.modalService.open<LogoutPromptComponent, LogoutPromptAction>(
        LogoutPromptComponent,
        {
          data: {
            // TODO track other active session
            // check rocket status
            busy: this.rocketLcService.hasActiveTransfers(),
          },
          hasBackdrop: true,
        },
        {
          hasBackdropClick: false,
          hasEscapeClose: false,
          isCentered: true,
        }
      )

      ev.events().subscribe((n) => {
        r(n.name == 'Logout')
      })
    })
  }

  refreshSession() {
    this.getPermissions()
    this.getEntitlements()
  }

  getColorPalette() {
    return COLOR_PALETTE
  }

  getUserLabelColorCode(label: string = 'BB') {
    const token = label?.trim()?.split(' ') || 'BB'
    let key = ''

    if (token.length < 2) key = label?.slice(0, 2)
    else {
      key = token[0][0] + token[token.length - 1][token.length - 1]
    }

    if (key?.length < 2) key = (key + 'BB').slice(0, 2)

    key = key.toUpperCase()

    const c = key.codePointAt(0) * key.codePointAt(1) * 31

    // From chrome dev tool color suggestion
    const color = COLOR_PALETTE

    return color[c % color.length]
  }

  generateUserSvgBase64(name: string = 'BB') {
    const token = name?.trim()?.split(' ') || 'BB'
    let key = ''

    if (token.length < 2) key = name?.slice(0, 2)
    else {
      key = token[0][0] + token[token.length - 1][0]
    }

    if (key?.length < 2) key = (key + 'BB').slice(0, 2)

    key = key.toUpperCase()

    const c = key.codePointAt(0) * key.codePointAt(1) * 31
    const color = COLOR_PALETTE

    return window.btoa(
      `<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="16" fill="${
        color[c % color.length]
      }" /><text font-family="Proxima-Nova" x="50%" y="50%" text-anchor="middle" fill="white" font-size="16px" dy=".3em">${key}</text></svg>`
    )
  }

  generateUserIcon(name: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(`data:image/svg+xml;base64, ${this.generateUserSvgBase64(name)}`)
  }

  getDefaultNoAvatar() {
    return '/assets/img/avatars/noavatar.png'
  }

  getSystemIcon() {
    return '/assets/img/bebop_monogram.png'
  }

  getUserImage(user?: User) {
    // Use the passed user object, or fallback to this.user if no user is passed
    const currentUser = user || this.user

    if (currentUser?.picture?.startsWith(this.config.apiUrl)) {
      return currentUser.picture
    }

    let picture = ''
    if (
      !currentUser.picture ||
      (!currentUser.picture.includes('assets/img/avatars/2023') && !currentUser.picture.includes('img/avatars/2023'))
    ) {
      if (currentUser.name) {
        // If user object is passed, don't update the session, just return the picture
        const pictureIcon = this.generateUserIcon(currentUser.name)
        if (!user) {
          this.sessionService.updateUserInfo({ picture: '', pictureIcon: pictureIcon })
        }
        return pictureIcon
      } else {
        picture = this.getDefaultNoAvatar()
      }
    } else {
      picture = !currentUser.picture.includes('assets')
        ? 'assets' + encodeURI(currentUser.picture)
        : encodeURI(currentUser.picture)
    }

    return picture
  }

  private getEntitlements() {
    this.sessionService.getEntitlements().subscribe((res: EntitlementsResponse) => {
      if (res.error) {
        console.error('Error getting entitlements', res.error)
        return
      }
      this.entitlements = res
      this.sessionService.setEntitlements(res)
      this.getOrganizations()
    })
  }

  private getOrganizations() {
    this.sessionService.getOrgs(this.user._id).subscribe((res: OrganizationsResponse) => {
      if (res.error) {
        console.error('Error getting organizations', res.error?.msg || res.error)
        return
      }

      // this can be called while refresh
      this.sessionService.setToken(res.data.token)
      if (res?.data?.organizations?.length) {
        // sort by name
        res.data.organizations.sort((l, r) => (l.name > r.name ? 1 : l.name < r.name ? -1 : 0))
        this.sessionService.setOrganizations(res.data?.organizations)
        this.getOrgWatermark(res.data?.organizations)
      } else {
        this.uiService.setSelectedOrg(null)
        return
      }

      const selectedOrg = this.uiQuery.getSelectedOrgValue()
      if (selectedOrg) {
        if (!res?.data?.organizations?.some((o) => o._id == selectedOrg?._id && !o?.suspended)) {
          // set again to update nav permissions
          this.uiService.setSelectedOrg(selectedOrg)
          return
        } else {
          this.uiService.setSelectedOrg(null)
        }
      }

      const activeOrgs = res?.data?.organizations?.filter((o) => !o?.suspended) ?? []

      if (activeOrgs?.length > 0) {
        // Load the user selected org
        let lastUsedOrg = this.getLocalStorageItem('lastSelectedOrg')

        let lastSelectedOrg = activeOrgs.find((o) => o._id == lastUsedOrg)
        this.uiService.setSelectedOrg(lastSelectedOrg || activeOrgs[0])
      }
    })
  }

  private getPermissions() {
    this.sessionService.getUserPermissions(this.user._id).subscribe((res: PermissionsResponse) => {
      if (res?.error || !res) {
        console.error('Error getting permissions', res?.error?.msg || res?.error)
        return
      }

      const permissions = res?.permissions

      const accessLinks = permissions?.some((p) => p?.name == UserLevelPermission.ACCESS_BEBOP_LINKS_VIA_CLIENT)

      this.uiService.setUserPermissions({ links: !!accessLinks })
    })
  }

  private getOrgWatermark(orgs: Organization[]) {
    this.sessionService
      .getOrgWatermark({ organizations: orgs.map((o) => o._id) })
      .subscribe((res: OrgWatermarkResponse) => {
        if (res.error) {
          console.error('Error getting watermark', res.error?.msg || res.error)
          return
        }

        orgs = orgs.map((o) => {
          const watermark = res.data.find((w) => w.organization === o._id)?.watermark
          if (watermark) o.logo = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${watermark}`)
          return o
        })

        this.sessionService.setOrganizations(orgs)
      })
  }

  private getPods(orgId: string) {
    this.uiService.setLoadingPods(true)
    this.sessionService.getPods(orgId).subscribe((res: PodsResponse) => {
      this.uiService.setLoadingPods(false)
      if (res.error) {
        return
      }
    })
  }

  getAnouncements(cb?: CallbackFunction) {
    this.sessionService.getAnnouncements().subscribe((res: AnnouncementsResponse) => {
      if (res?.error) {
        console.error('Error getting announcements', res?.error?.msg || res.error)
        return cb?.()
      }

      cb?.()
    })
  }

  private setNavPermissions(permissions: Entitlement = {}) {
    this.navPermissions.earthDashboard =
      (permissions.ACCESS_EARTH_DASHBOARD || permissions.ACCESS_EARTH_MEDIASERVER_DASHBOARD) ?? false

    // no need to disable other permissions for earth user, just honor the entitlement
    // if (this.navPermissions.earthDashboard) {
    //   this.navPermissions.earthMediaServerDashboard = permissions.ACCESS_EARTH_MEDIASERVER_DASHBOARD ?? false
    //   this.navPermissions.upload = false
    //   this.navPermissions.download = false
    //   this.navPermissions.hotfolder = false
    //   this.navPermissions.desktop = false
    //   this.navPermissions.flex = false
    //   this.navPermissions.cast = false
    //   this.uiService.setNavPermissions(this.navPermissions)
    //   return
    // }

    this.navPermissions.upload = permissions.UPLOAD ?? false
    this.navPermissions.download = permissions.DOWNLOAD ?? false
    this.navPermissions.hotfolder =
      (permissions.HOTFOLDER_UPLOAD || permissions.HOTFOLDER_DOWNLOAD || permissions.HOTFOLDER_TWO_WAY) ?? false
    this.navPermissions.desktop = permissions.ACCESS_DESKTOP ?? false
    this.navPermissions.flex =
      (permissions.ACCESS_STORAGE_PROFILE ||
        permissions.ACCESS_FLEX_CLIENT_MOUNT ||
        permissions.FLEX_CLIENT_MOUNT ||
        permissions.ACCESS_BEBOP_FLEX_LINKS) ??
      false
    this.navPermissions.cast = permissions.ACCESS_DESKTOP ?? false // same as desktop
    this.navPermissions.users = permissions.ADMIN_ORGANIZATION ?? false
    this.navPermissions.projects = permissions.ACCESS_BEBOP_PROJECTS
    this.uiService.setNavPermissions(this.navPermissions)
  }

  public startPowerSaveBlocker() {
    const _this = this
    this.userSettings.readSettings('UserSettings', this.user._id, (err, settings) => {
      const userSettings = settings || {}
      const powerSaveBlocker =
        (userSettings.appSettings ? userSettings.appSettings.powerSaveBlocker : 'prevent-app-suspension') || 'none'
      _this.electronService.notifyMain('user.app.power-saver', { value: powerSaveBlocker })
    })
  }

  onChangeOrganization(o: Organization) {
    this.selectedOrg = o
    this.getWorkstationRegions()
    this.selectedPod$?.unsubscribe()

    this.selectedPod$ = this.sessionQuery.getPodsForCurrentOrg().subscribe((pods) => {
      if (pods?.length > 0) {
        // Always select the first pod
        this.uiService.setSelectedPod(pods[0])
      }
    })
  }

  goToDashboard() {
    // lets keep same dashboard for all users
    this.router.navigate(['app/dashboard'], {})
  }

  routeTo(route: string, opts: any = {}) {
    // tODO : this is wrong, check permisisons
    this.router.navigate([route], opts)
  }

  getAnnouncementItem(key: AnnouncementKey) {
    return this.getLocalStorageItem(key, true)
  }

  setAnnouncementItem(key: AnnouncementKey, value: string) {
    return this.setLocalStorageItem(key, value)
  }

  getLocalStorageItem(key: string, noParse = true) {
    return this.clientUtils.getLocalStorageItem(key, noParse)
  }

  setLocalStorageItem(key: string, value: any) {
    return this.clientUtils.setLocalStorageItem(key, value)
  }

  warningPrompt(ev: Event, data: WarningPromptPayload, overlayConfig?: CustomOverlayConfig) {
    return new Promise<boolean>((r) => {
      const ref = this.modalService.open<WarningPromptComponent, WarningPromptAction>(
        WarningPromptComponent,
        {
          animateFrom: ev?.target as Element,
          data,
          hasBackdrop: true,
        },
        {
          hasBackdropClick: true,
          hasEscapeClose: true,
          isCentered: true,
          ...overlayConfig,
        }
      )

      ref.events().subscribe((e) => {
        r(e.name != 'Cancel')
      })
    })
  }
}
